import React, { useEffect, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaEdit,
  FaPlus,
  FaSearch,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios"; // Pastikan sudah menginstal axios
import Back from "../../components/button/back";

function KelolaBerita() {
  const [berita, setBerita] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;

  const navigate = useNavigate();

  // Fetch data dari API
  useEffect(() => {
    const fetchBerita = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/berita`
        );
        setBerita(response.data);
      } catch (error) {
        console.error("Error fetching berita:", error);
      }
    };
    fetchBerita();
  }, []);

  const handleEditBerita = (id) => {
    navigate("/addberita", { state: { ID: id } });
  };

  const handleDeleteBerita = async (id, gambar) => {
    const confirmDelete = window.confirm(
      "Apakah Anda yakin ingin menghapus berita ini?"
    );
    if (!confirmDelete) return;

    try {
      // // Hapus gambar dari Firebase Storage
      // console.log(gambar);
      // const fileRef = ref(storage, `berita/${gambar}`);
      // console.log("Gambar path:", fileRef);  // Memeriksa apakah referensi file sudah benar

      // await deleteObject(fileRef);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/berita/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Menyegarkan data berita dari server setelah penghapusan
        const freshData = await fetch(
          `${process.env.REACT_APP_API_URL}/api/berita`
        );
        const updatedBerita = await freshData.json();
        setBerita(updatedBerita); // Update state dengan data terbaru

        alert("Berita dan gambar berhasil dihapus.");
      } else {
        const error = await response.json();
        console.error("Error response from API:", error);
        alert("Gagal menghapus berita. Coba lagi nanti.");
      }
    } catch (error) {
      console.error("Error saat menghapus berita dan gambar:", error);
      alert("Terjadi masalah dengan server.");
    }
  };

  const filteredBerita = berita.filter((item) =>
    item.judul.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredBerita.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredBerita.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="container mx-auto p-6">
      {/* Pencarian dan tombol Add Berita */}
      <div className="mb-6 flex items-center space-x-2">
        <FaSearch className="text-gray-500" />
        <input
          type="text"
          placeholder="Cari berita..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        <button
          onClick={() => navigate("/addberita")}
          className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center space-x-2 w-48"
        >
          <FaPlus />
          <span>Tambah Berita</span>
        </button>
      </div>

      {/* Daftar Berita */}
      <div>
        <h2 className="text-2xl font-bold mb-4">Daftar Berita</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentItems.map((item) => (
            <div
              key={item.id}
              className="border rounded-lg overflow-hidden shadow-md"
            >
              <img
                src={item.gambar}
                alt={item.judul}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h6 className="text-xs text-gray-500">
                  {new Date(item.tanggal).toLocaleDateString("id-ID")}
                </h6>
                <h4 className="font-bold text-lg mb-2">{item.judul}</h4>
                <p className="text-sm text-gray-700 mb-2">{item.deskripsi}</p>

                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEditBerita(item._id)}
                    className="bg-yellow-500 text-white px-4 py-1 rounded-md"
                  >
                    <FaEdit /> Edit
                  </button>

                  <button
                    onClick={() => handleDeleteBerita(item._id, item.gambar)}
                    className="bg-red-500 text-white px-4 py-1 rounded-md"
                  >
                    Hapus
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Paginasi */}
      <div className="flex justify-center items-center space-x-4 mt-4">
        <button
          className={`px-4 py-2 rounded ${
            currentPage === 1
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-red-500 text-white"
          }`}
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <FaArrowLeft />
        </button>
        <span className="px-4 py-2">
          {currentPage} / {totalPages}
        </span>
        <button
          className={`px-4 py-2 rounded ${
            currentPage === totalPages
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-red-500 text-white"
          }`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <FaArrowRight />
        </button>
      </div>
      <div className="flex justify-center my-5">

                <Link to={"/admin"}>
                    <Back />
                </Link>
            </div>
    </div>
  );
}

export default KelolaBerita;
