import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../components/button/back";

const ElearningSiswa = () => {
  const { mapel } = useParams();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userClass, setUserClass] = useState(null);
  const [userRombel, setUserRombel] = useState(null);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 404) {
        if (error.config.url.includes(`/api/questions/mapel/${mapel}`)) {
          return Promise.resolve({ data: [] });
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userKelas = response.data.kelas;
        const Rombel = response.data.rombel;
        setUserRombel(Rombel);
        setUserClass(userKelas);
        console.log("User Class:", Rombel);
      } catch (error) {
        setError("An error occurred while fetching user data");
      }
    };

    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/mapel/${mapel}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredQuestions = response.data.filter(
          (question) =>
            question.kelas === userClass &&
            question.status === true &&
            question.rombel === userRombel
        );

        setQuestions(filteredQuestions);
        console.log(`Filtered Questions:`, filteredQuestions);
      } catch (error) {
        if (!error.response || error.response.status !== 404) {
          setError("An error occurred while fetching questions");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData().then(fetchQuestions);
  }, [mapel, userClass, userRombel]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className=" min-h-screen bg-blue-100">
      <div className="flex justify-center items-center">
        <h2 className="text-3xl font-bold pt-10">Matapelajaran {mapel}</h2>
      </div>
      <div className="flex justify-center items-center">
        {questions.length === 0 ? (
          <div>Tidak ada pekerjaan untuk matapejaran {mapel}</div>
        ) : (
          <ul className="grid grid-cols-2 justify-center items-center gap-x-3">
            {questions.map((q) => (
              <li
                key={q._id}
                className="bg-blue-300  drop-shadow-lg my-5 w-40 h-40 rounded-lg grid justify-center items-center"
              >
                <div className="grid justify-center items-center gap-y-10">
                  <div className="">
                    <div className="flex justify-center">
                      <p className="font-bold">{q.jenis}</p>
                    </div>
                    <div className="flex justify-center">
                      <p>Soal: {q.kode}</p>
                    </div>
                    <div className="flex justify-center">
                      <p>
                        Kelas: {q.kelas}
                        {q.rombel}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-center items-center rounded-lg bg-red-400 hover:bg-red-700 w-20 h-10">
                    <Link
                      to={`/siswa/elearning-siswa/${mapel}/question/${q._id}`}
                    >
                      <button className="">Kerjakan</button>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex justify-center mt-10">
        <Link to={"/siswa"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default ElearningSiswa;
