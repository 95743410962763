import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../components/button/back";
import CRUDnilaiTotal from "./CRUDnilaiTotal";

const Nilai1 = () => {
  const { questionId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [OptionCorrectAnswer, setOptionCorrectAnswer] = useState({}); // Disimpan per soal
  const [questionData, setQuestionData] = useState(null);
  const [tahun, setTahun] = useState(String(new Date().getFullYear()));
  const [semester, setSemester] = useState("");
  const [warning, setWarning] = useState("");

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuestionData(response.data);

        // Ambil `OptionCorrectAnswer` untuk setiap soal
        const arrayCorrectAnswers = {};
        response.data.question.forEach((q, index) => {
          arrayCorrectAnswers[index] = q.optionCorrectAnswer.map((answer) =>
            answer.toLowerCase()
          );
        });
        setOptionCorrectAnswer(arrayCorrectAnswers);
      } catch (error) {
        console.error("Error fetching question data:", error);
      }
    };

    fetchQuestionData();
  }, [questionId]);

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/answers/question/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAnswers(response.data);

        const similarityPromises = response.data.map(async (answer) => {
          const questionComparisons = await Promise.all(
            answer.question.map(async (q, index) => {
              try {
                // Hanya periksa untuk q.type === "isian"
                if (q.type === "isian") {
                  const arrayCorrectAnswers = OptionCorrectAnswer[index] || [];
                  if (!arrayCorrectAnswers.length) {
                    return {
                      ...q,
                      similarity: 0,
                    };
                  }

                  const keyJawabanBenar = q.keyJawabanBenar || 1; // Default jika kosong
                  const userAnswerArray = Array.isArray(q.userAnswer)
                    ? q.userAnswer.map((ans) => ans.trim().toLowerCase())
                    : q.userAnswer
                        ?.split("|")
                        ?.map((ans) => ans.trim().toLowerCase()) || [];

                  const intersectionCount = userAnswerArray.filter((userAns) =>
                    arrayCorrectAnswers.includes(userAns)
                  ).length;

                  return {
                    ...q,
                    similarity: intersectionCount / keyJawabanBenar, // Persentase kesamaan
                  };
                }

                // Untuk q.type selain "essay", tetap proses dengan metode umum
                const correctAnswer = q.correctAnswer?.trim().toLowerCase();
                const userAnswer = q.userAnswer?.trim().toLowerCase();

                if (!correctAnswer || !userAnswer) {
                  return { ...q, similarity: 0 };
                }

                const compareResponse = await axios.post(
                  `${process.env.REACT_APP_API_URL}api/compare`,
                  {
                    text1: correctAnswer,
                    text2: userAnswer,
                    bobot: q.bobot,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                const similarity = compareResponse?.data?.similarity || 0;

                return {
                  ...q,
                  similarity,
                };
              } catch (error) {
                console.error("Error during comparison:", error);
                return { ...q, similarity: 0, isCorrect: 0 };
              }
            })
          );

          return { ...answer, question: questionComparisons };
        });

        const answersWithSimilarity = await Promise.all(similarityPromises);
        setAnswers(answersWithSimilarity);
      } catch (error) {
        console.error("Error fetching answers:", error);
      }
    };

    fetchAnswers();
  }, [questionId, OptionCorrectAnswer]);

  const handleDelete = async (userId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}api/answers/user/${userId}/question/${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAnswers((prevAnswers) =>
        prevAnswers.filter((answer) => answer.studentId._id !== userId)
      );
    } catch (error) {
      console.error("Error deleting answer:", error);
    }
  };

  const handleSave = async () => {
    if (!semester) {
      setWarning("Semester harus diisi terlebih dahulu.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const raporData = {
        questionId: questionId,
        createdBy: questionData.createdBy,
        mapel: questionData.mapel,
        jenis: questionData.jenis,
        kelas: questionData.kelas,
        rombel: questionData.rombel,
        tahun: tahun,
        semester: semester.toString(),
        nilai: answers.map((answer) => {
          const totalScore = answer.question.reduce((acc, q) => {
            if (q.type === "multipleChoice") {
              return acc + (q.isCorrect ? q.bobot : 0);
            } else if (q.type === "essay") {
              return acc + (q.similarity !== null ? q.similarity : 0);
            } else if (q.type === "isian") {
              return acc + (q.similarity !== null ? q.similarity : 0);
            }
            return acc;
          }, 0);
          return {
            studentId: answer.studentId._id,
            name: answer.studentId.name,
            nilaiTotal: parseFloat(totalScore.toFixed(2)),
          };
        }),
      };

      await axios.post(`${process.env.REACT_APP_API_URL}api/nilai`, raporData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      alert("Data rapor berhasil disimpan!");
    } catch (error) {
      console.error("Error saving rapor data:", error);
      if (error.response && error.response.data) {
        alert(`Gagal menyimpan data rapor: ${error.response.data.message}`);
      } else {
        alert("Gagal menyimpan data rapor.");
      }
    }
  };

  return (
    <div className="min-h-screen bg-blue-100">
      <div>
        <div className="flex justify-center py-10">
          <h2 className="text-2xl font-bold">Nilai </h2>
        </div>

        <div className="bg-white mx-5 md:mx-10 rounded-lg p-5">
          <div className="grid rounded-lg p-5 border border-slate-500 ">
            {questionData && (
              <div className="grid gap-y-2">
                <p>Kelas: {questionData.kelas}</p>
                <p>Rombel: {questionData.rombel}</p>
                <p>Mapel: {questionData.mapel}</p>
                <p>Jenis: {questionData.jenis}</p>
              </div>
            )}
            <div className="grid gap-y-2">
              <label>
                Tahun :
                <input
                  type="text"
                  value={tahun}
                  onChange={(e) => setTahun(e.target.value)}
                  className="border border-slate-500 rounded-lg px-5 md:w-auto w-4/6"
                />
              </label>
              <label>
                Semester :
                <select
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                  className="border border-slate-500 rounded-lg px-2"
                  required
                >
                  <option value="">pilih semester</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </label>
            </div>
            {warning && <p style={{ color: "red" }}>{warning}</p>}
          </div>

          <div className="border border-slate-500 mt-5 rounded-lg p-5">
            <table className="min-w-full bg-white text-sm lg:text-normal">
              <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
                <tr>
                  <th className="py-2 border">No</th>
                  <th className="py-2 px-4 border">Nama</th>
                  <th className="py-2 px-4 border">Total</th>
                  <th className="py-2 px-4 border">Hapus</th>
                </tr>
              </thead>
              <tbody>
                {answers.map((answer, index) => {
                  const totalScore = answer.question.reduce((acc, q) => {
                    if (q.type === "multipleChoice") {
                      return acc + (q.isCorrect ? q.bobot : 0);
                    } else if (q.type === "essay") {
                      return acc + (q.similarity !== null ? q.similarity : 0);
                    } else if (q.type === "isian") {
                      return acc + (q.similarity !== null ? q.similarity : 0);
                    }
                    return acc;
                  }, 0);

                  return (
                    <tr key={answer._id} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border">{index + 1}</td>
                      <td className="py-2 px-4 border">
                        {answer.studentId.name}
                      </td>
                      <td className="py-2 px-4 border">
                        {totalScore.toFixed(2)}
                      </td>
                      <td className="py-2 px-4 border">
                        <button
                          className="text-red-500 hover:text-red-700"
                          onClick={() => handleDelete(answer.studentId._id)}
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center ">
            <button
              className="py-2 bg-blue-300 mt-5 rounded-lg w-40 text-white font-bold hover:bg-blue-700"
              onClick={handleSave}
            >
              Simpan Rapor
            </button>
          </div>
        </div>
        <div className="flex justify-center pt-10">
          <Link to={"/guru"}>
            <Back />
          </Link>
        </div>
      </div>
      <div>
        <div>
          <CRUDnilaiTotal
            mapel={questionData?.mapel}
            questionId={questionId}
            kelas={questionData?.kelas}
            rombel={questionData?.rombel}
            tahun={tahun}
            semester={semester}
            jenis={questionData?.jenis}
          />
        </div>
      </div>
    </div>
  );
};

export default Nilai1;
