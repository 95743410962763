import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Correctly imported 'jwt-decode'
import React, { useEffect, useState } from "react";
import TabelPersen from "./TabelPersen";

const Persen = ({ mapel }) => {
  const [persenData, setPersenData] = useState({
    Latihan: 0,
    PR: 0,
    Tugas: 0,
    UH: 0,
    MID: 0,
    US: 0,
    Praktikum: 0,
  });
  const [totalPersen, setTotalPersen] = useState(0);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // State to handle editing

  useEffect(() => {
    const fetchPersen = async () => {
      try {
        const token = localStorage.getItem("token");
        const decoded = jwtDecode(token);
        const userId = decoded.user.id;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/persen/mapel/${mapel}/user/${userId}`,
          config
        );

        setPersenData(response.data);
        calculateTotal(response.data); // Calculate total after fetching data
      } catch (error) {
        // Handle errors
        setPersenData({
          Latihan: 0,
          PR: 0,
          Tugas: 0,
          UH: 0,
          MID: 0,
          US: 0,
          Praktikum: 0,
        });
        calculateTotal({}); // Calculate total with empty data
        if (error.response && error.response.status === 404) {
          setError(
            "Silakan pilih Edit untuk memasukkan persentasi nilai terlebih dahulu."
          );
        } else {
          setError("Error fetching persen: " + error.message);
        }
      }
    };

    fetchPersen();
  }, [mapel]);

  const calculateTotal = (data) => {
    const total = Object.values(data).reduce(
      (total, value) => total + (Number(value) || 0), // Ensure valid number or default to 0
      0
    );
    setTotalPersen(total);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSubmit = () => {
    setIsEditing(false);
    window.location.reload(); // Reload the page after submission
  };

  return (
    <div className="container mx-auto p-10 my-10">
      <h2 className="text-2xl font-bold mb-4">Persen Yang Digunakan</h2>
      {error && <p className="text-red-500">{error}</p>}
      {!isEditing ? (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {["Latihan", "PR", "Tugas", "UH", "MID", "US", "Praktikum"].map(
              (jenis, index) => (
                <div key={index} className="flex items-center mb-2">
                  <label className="w-24 font-bold text-lg text-gray-700">
                    {jenis}
                  </label>
                  <input
                    type="number"
                    value={persenData[jenis] || 0}
                    readOnly
                    className="border border-gray-300 p-2 rounded-md flex-1"
                  />
                  %
                </div>
              )
            )}
            <div className="flex items-center mb-2">
              <label className="w-24 font-bold text-lg text-gray-700">
                Total
              </label>
              <input
                type="number"
                value={totalPersen || 0}
                readOnly
                className="border border-gray-300 p-2 rounded-md flex-1 "
              />
              %
            </div>
          </div>
          <button
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={handleEditClick}
          >
            Edit
          </button>
        </>
      ) : (
        <TabelPersen mapel={mapel} onSubmit={handleSubmit} />
      )}
    </div>
  );
};

export default Persen;
