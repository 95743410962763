import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Berita = () => {
  const navigate = useNavigate();
  // State untuk menyimpan data berita
  const [berita, setBerita] = useState([]);
  const [loading, setLoading] = useState(true); // Untuk menampilkan loading
  const [error, setError] = useState(null); // Untuk menangani error

  // Fungsi untuk memformat tanggal menjadi dd-mm-yyyy
  const formatTanggal = (tanggal) => {
    const date = new Date(tanggal);
    const day = String(date.getDate()).padStart(2, "0"); // Menambahkan leading zero jika kurang dari 10
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()]; // Mendapatkan nama bulan dalam format 3 huruf
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  // Mengambil data berita dari API
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/berita`)
      .then((response) => response.json())
      .then((data) => {
        setBerita(data); // Menyimpan data berita
        setLoading(false); // Menghentikan loading
      })
      .catch((error) => {
        setError(error); // Menangani error
        setLoading(false); // Menghentikan loading
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Menampilkan loading saat data belum siap
  }

  if (error) {
    return <div>Error: {error.message}</div>; // Menampilkan pesan error jika ada
  }
  const handleClick = () => {
    navigate("/berita");
  };
  return (
    <div className="my-10 bg-[#BDA6E7] py-10 px-5">
      <h1 className="text-center text-4xl text-white font-bold mb-10">
        Berita Terbaru
      </h1>

      {/* Kontainer Grid dengan Flex untuk meratakan card */}
      <div className="flex justify-center items-center space-y-8">
        <div className="w-full max-w-5xl">
          {berita.slice(0, 3).map(
            (
              item,
              index // Hanya menampilkan 3 berita terbaru
            ) => (
              <div
                key={item.id}
                className={`grid grid-cols-9 gap-4 items-center rounded-lg p-5 ${
                  index < 2 ? "border-b-2 border-gray-300" : ""
                }`}
              >
                {/* Kolom Tanggal */}
                <div className="col-span-1 mb-20 font-mono text-white font-bold lg:text-3xl text-md ">
                  {formatTanggal(item.tanggal)}
                </div>

                {/* Kolom Foto */}
                <div className="col-span-3">
                  <img
                    src={item.gambar}
                    alt={item.judul}
                    className="w-full h-auto mb-20 lg:mb-0 lg:h-auto rounded-md"
                  />
                </div>

                {/* Kolom Judul */}
                <div className="col-span-4">
                  <h2 className="font-mono text-lg lg:text-2xl font-bold text-white mb-3">
                    {item.judul}
                  </h2>
                  <button
                    className="px-10 py-1 bg-yellow-500 text-white text-sm font-medium rounded-full hover:bg-yellow-600"
                    onClick={handleClick}
                  >
                    Selengkapnya
                  </button>
                </div>
              </div>
            )
          )}
          <div className="flex justify-center">
            <button className="flex flex-center px-10 py-2 bg-orange-500 text-white text-lg rounded-lg hover:bg-orange-600">
              Baca Berita Lainnya ....
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Berita;
