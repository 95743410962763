import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid"; // Import uuidv4 untuk membuat UUID
import Back from "../components/button/back";
import { storage } from "./FirebaseImage"; // Sesuaikan dengan path Anda

const EditEkskul = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [ekskul, setEkskul] = useState({
    Judul: "",
    Deskripsi: "",
    Gambar: "",
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchEkskul = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/ekskul/${id}`
        );
        const ekskulData = response.data;
        setEkskul(ekskulData);
      } catch (error) {
        console.error("Error fetching ekskul data:", error);
      }
    };

    fetchEkskul();
  }, [id]);

  const handleChange = (e) => {
    setEkskul({ ...ekskul, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageUrl = ekskul.Gambar; // Mulai dengan menggunakan nilai ekskul.Gambar

    // Jika ada file yang diunggah baru, hapus gambar lama dan unggah yang baru
    if (file) {
      // Upload gambar baru
      const fileName = `${uuidv4()}-${file.name}`;
      const storageRef = ref(storage, `ekskul/${fileName}`);
      try {
        const uploadResult = await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(uploadResult.ref);
        console.log("URL Gambar Baru:", imageUrl);
      } catch (uploadError) {
        console.error("Error uploading image:", uploadError);
        return;
      }
    } else {
      // Jika tidak ada file yang diunggah baru, pastikan imageUrl tetap ekskul.Gambar
      imageUrl = ekskul.Gambar || null;
    }

  

    try {
      // Kirim permintaan PUT untuk memperbarui data ekskul di server
      await axios.put(
        `${process.env.REACT_APP_API_URL}api/ekskul/${id}`,
        {
          Judul: ekskul.Judul,
          Deskripsi: ekskul.Deskripsi,
          Gambar: imageUrl,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data Ekskul yang Diperbarui:", {
        Judul: ekskul.Judul,
        Deskripsi: ekskul.Deskripsi,
        Gambar: imageUrl,
      });
      setEkskul({ ...ekskul, Gambar: imageUrl }); // Perbarui state ekskul dengan nilai Gambar yang baru atau lama
      navigate("/admin/ektrakulikuler");
    } catch (error) {
      console.error("Error updating ekskul:", error);
    }
  };

  return (
    <div className="container mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-5">Edit Ekskul</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Judul"
          >
            Judul
          </label>
          <input
            type="text"
            name="Judul"
            value={ekskul.Judul}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Gambar"
          >
            Gambar
          </label>
          <input
            type="file"
            name="Gambar"
            onChange={handleFileChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          {ekskul.Gambar && (
            <img
              src={ekskul.Gambar}
              alt={ekskul.Judul}
              className="w-16 h-16 object-cover mt-2"
            />
          )}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="Deskripsi"
          >
            Deskripsi
          </label>
          <textarea
            name="Deskripsi"
            value={ekskul.Deskripsi}
            onChange={handleChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="5"
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Update
        </button>
      </form>
      <div className="flex justify-center my-5">
        <Link to={"/admin/ektrakulikuler"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EditEkskul;
