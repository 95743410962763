import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CRUDnilaiTotal = ({
  mapel,
  questionId,
  kelas,
  rombel,
  tahun,
  semester,
  jenis,
}) => {
  const [nilaiData, setNilaiData] = useState([]);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    nilaiTotal: 0,
  });

  useEffect(() => {
    const fetchNilai = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilai/mapel/${mapel}/question/${questionId}/kelas/${kelas}/rombel/${rombel}/tahun/${tahun}/semester/${semester}/jenis/${jenis}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Data yang diambil:", response.data); // Log data yang diambil dari respons API
        setNilaiData(response.data || []); // Assign the data to nilaiData
      } catch (error) {
        setError("Error fetching data: " + error.message);
      }
    };

    // Fetch data only when all required props are available
    if (mapel && questionId && kelas && rombel && tahun && semester && jenis) {
      fetchNilai();
    }
  }, [mapel, questionId, kelas, rombel, tahun, semester, jenis]);

  const handleUpdateClick = (nilaiItem, item) => {
    setCurrentData({ ...nilaiItem, studentId: item.studentId._id });
    setFormData({
      name: item.studentId.name,
      nilaiTotal: item.nilaiTotal,
    });
    setEditMode(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validasi input sebelum mengirimkan permintaan
    if (formData.name.trim() === "") {
      setError("Nama harus diisi");
      return;
    }

    if (formData.nilaiTotal <= 0) {
      setError("Nilai total harus lebih besar dari 0");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const updatedData = {
        ...currentData,
        nilai: currentData.nilai.map((item) =>
          item.studentId._id === currentData.studentId
            ? { ...item, name: formData.name, nilaiTotal: formData.nilaiTotal }
            : item
        ),
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/nilai/${currentData._id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Updated data:", response.data); // Log updated data
      setNilaiData((prevData) =>
        prevData.map((item) =>
          item._id === currentData._id ? { ...item, ...updatedData } : item
        )
      );
      setEditMode(false);
    } catch (error) {
      setError("Error updating data: " + error.message);
    }
  };

  const handleDeleteAll = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}api/nilai/mapel/${mapel}/question/${questionId}/kelas/${kelas}/rombel/${rombel}/tahun/${tahun}/semester/${semester}/jenis/${jenis}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("All data deleted"); // Log deletion of all data
      setNilaiData([]); // Clear the data in the state
    } catch (error) {
      setError("Error deleting all data: " + error.message);
    }
  };

  // Conditional rendering for error
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="grid mx-10">
      <h2 className="text-xl font-bold mb-4">Nilai Total</h2>
      <div>
        <Link to={`/guru/inputnilaimanual/${questionId}`}>
          <button className="bg-blue-400 font-bold text-white p-3 rounded-lg hover:bg-blue-600">
            Input Nilai Secara Manual
          </button>
        </Link>
      </div>

      <div className="overflow-x-auto shadow-md sm:rounded-lg my-10">
        {nilaiData.length === 0 ? (
          <div>No data available</div>
        ) : editMode ? (
          <form
            onSubmit={handleFormSubmit}
            className="bg-white p-4 rounded shadow-md"
          >
            <div className="mb-5">
              <label className="block mb-2">
                Nama:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                  className="block w-full mt-1 border border-gray-300 rounded p-2"
                />
              </label>
            </div>
            <div className="mb-5">
              <label className="block mb-2">
                Nilai Total:
                <input
                  type="number"
                  name="nilaiTotal"
                  value={formData.nilaiTotal}
                  onChange={handleFormChange}
                  className="block w-full mt-1 border border-gray-300 rounded p-2"
                />
              </label>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded mt-2 mr-5"
            >
              Update
            </button>
            <button
              onClick={() => setEditMode(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded mt-2"
            >
              Cancel
            </button>
          </form>
        ) : (
          <div>
            <button
              onClick={handleDeleteAll}
              className="bg-red-500 text-white px-4 py-2 rounded mb-4"
            >
              Hapus Semua
            </button>
            <table className="min-w-full bg-white border">
              <thead className="bg-gray-200">
                <tr>
                  <th className="py-2 px-4 border">No</th>
                  <th className="py-2 px-4 border">Nama</th>
                  <th className="py-2 px-4 border">Nilai</th>
                  <th className="py-2 px-4 border">Tahun</th>
                  <th className="py-2 px-4 border">Semester</th>
                  <th className="py-2 px-4 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {nilaiData.map((nilaiItem, index) =>
                  nilaiItem.nilai.map((item, subIndex) => (
                    <tr
                      key={`${index}-${subIndex}`}
                      className="hover:bg-gray-100"
                    >
                      <td className="py-2 px-4 border">
                        {index * nilaiData.length + subIndex + 1}
                      </td>
                      <td className="py-2 px-4 border">
                        {item.studentId && item.studentId.name
                          ? item.studentId.name
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4 border">
                        {typeof item.nilaiTotal === "number" &&
                        !isNaN(item.nilaiTotal)
                          ? item.nilaiTotal.toFixed(2)
                          : "N/A"}
                      </td>
                      <td className="py-2 px-4 border">{nilaiItem.tahun}</td>
                      <td className="py-2 px-4 border">{nilaiItem.semester}</td>
                      <td className="py-2 px-4 border">
                        <button
                          onClick={() => handleUpdateClick(nilaiItem, item)}
                          className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default CRUDnilaiTotal;
