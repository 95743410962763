import axios from "axios";
import React, { useEffect, useState } from "react";
const Moto = () => {
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`
        );
        setSchoolData(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="m-10">
      <div className=" rounded-xl  md:h-auto md:w-auto bg-white">
        <div className="grid lg:flex  justify-center ">
          <div className="flex justify-center">
            <div className="lg:pt-0 ">
              {schoolData.map((user) => (
                <div key={user._id}>
                  {user.visi.map((data) => (
                    <div
                      key={data._id}
                      className="flex justify-center mx-10 my-5 lg:mx-32"
                    >
                      <p className="text-md font-serif">" {data.isiVisi} "</p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Moto;
