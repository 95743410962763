import React, { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

const Foto = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const swiperRef = useRef(null); // Ref untuk swiper instance

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/auth/user-images-and-roles`
        );
        const userData = await response.json();
        if (response.ok) {
          const filteredData = userData.filter((user) => user.role === "guru");
          setData(filteredData);
        } else {
          setError(userData.msg);
        }
      } catch (err) {
        setError("An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-white h-[490px] mx-10 rounded-xl">
      <div className="bg-white h-72 flex items-center flex-col rounded-xl">
        <div className="flex justify-center pt-5">
          <h1 className=" font-mono font-bold text-4xl text-[#F9A71A]">
            Pengajar dan Staff
          </h1>
        </div>

        <div className="relative mt-5 mx-auto max-w-full w-full sm:w-5/6 md:mx-20 lg:mx-0">
          {/* Left arrow icon */}
          <div
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
            onClick={() => swiperRef.current.swiper.slidePrev()} // Menavigasi ke slide sebelumnya
          >
            <FiChevronLeft
              size={30}
              className="text-white bg-gray-400 p-2 rounded-full cursor-pointer hover:bg-gray-400"
            />
          </div>

          {/* Right arrow icon */}
          <div
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
            onClick={() => swiperRef.current.swiper.slideNext()} // Menavigasi ke slide berikutnya
          >
            <FiChevronRight
              size={30}
              className="text-white bg-gray-400 p-2 rounded-full cursor-pointer hover:bg-gray-400"
            />
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <Swiper
              ref={swiperRef} // Menyimpan referensi Swiper
              breakpoints={{
                400: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                760: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
              }}
            >
              {data.map((user, index) => (
                <SwiperSlide key={index}>
                  <div className="grid justify-center items-center rounded-lg h-[400px] drop-shadow-lg hover:scale-110">
                    <div className="bg-white p-2 rounded-xl drop-shadow-lg">
                      <img
                        src={user.image}
                        alt={user.name}
                        className="w-[180px] h-[240px] object-cover rounded-lg"
                      />
                      <div className="flex justify-center">
                        <p className="my-2">{user.name}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default Foto;
