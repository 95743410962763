import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ProfileSiswa = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [kelas, setKelas] = useState(""); // Variable to store kelas
  const [rombel, setRombel] = useState(""); // Variable to store rombel

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("Anda harus login untuk mengakses halaman ini.");
          navigate("/login");
          return;
        }

        const decoded = jwtDecode(token);
        if (!decoded) {
          alert("Token tidak valid. Silakan login kembali.");
          navigate("/login");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilaifinal/all`,
          config
        );

        // Filter data berdasarkan studentId
        const filtered = response.data.filter((item) =>
          item.nilai.some((nilai) => nilai.studentId === id)
        );

        // Ambil kelas dan rombel siswa
        const siswaData = response.data.find((item) =>
          item.nilai.some((nilai) => nilai.studentId === id)
        );
        if (siswaData) {
          setKelas(siswaData.kelas);
          setRombel(siswaData.rombel);
        }

        // Filter data berdasarkan kelas dan rombel
        const dataFilteredByClassAndRombel = filtered.filter(
          (item) => item.kelas === kelas && item.rombel === rombel
        );

        setFilteredData(dataFilteredByClassAndRombel);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Terjadi kesalahan");
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate, kelas, rombel]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Fungsi untuk mengelompokkan data per mapel dan semester
  const groupedData = filteredData.reduce((acc, item) => {
    const { mapel, tahun, semester, nilai } = item;

    if (!acc[mapel]) {
      acc[mapel] = {};
    }

    if (!acc[mapel][tahun]) {
      acc[mapel][tahun] = {};
    }

    // Menyimpan nilai untuk semester 1 dan semester 2
    acc[mapel][tahun][semester] = nilai.find((n) => n.studentId === id)?.finalScore || "-";

    return acc;
  }, {});

  // Ambil daftar mapel, tahun dan semester
  const mapels = Object.keys(groupedData);
  const allYears = Array.from(
    new Set(filteredData.map((item) => item.tahun))
  ).sort();
  const semesters = ["1", "2"];

  // Membuat data untuk grafik batang
  const chartData = {
    labels: mapels, // Semua mapel di sumbu X
    datasets: semesters.map((sem, index) => {
      const data = mapels.map((mapel) => {
        // Ambil nilai per mapel untuk setiap tahun dan semester
        const mapelData = groupedData[mapel];
        const nilai = allYears.map((year) => mapelData[year]?.[sem] || 0);
        return nilai[0]; // Ambil nilai semester yang benar (semester 1 atau 2)
      });

      return {
        label: `Semester ${sem}`,
        data: data,
        backgroundColor: index === 0 ? 'rgba(255, 99, 132, 0.2)' : 'rgba(53, 162, 235, 0.2)',
        borderColor: index === 0 ? 'rgba(255, 99, 132, 1)' : 'rgba(53, 162, 235, 1)',
        borderWidth: 1,
      };
    }),
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h1 className="text-xl font-bold mb-6">Profil Nilai Siswa</h1>

      {/* Grafik Batang */}
      <div className="w-full md:w-4/5 mb-6">
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "Nilai per Mapel dan Semester",
              },
              legend: {
                position: 'top',
              },
            },
          }}
        />
      </div>

      {/* Tabel */}
      <div className="w-full md:w-4/5 overflow-x-auto">
        <table className="table-auto mx-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th rowSpan="2" className="px-4 py-3 border border-gray-300 text-center">Mapel</th>
              {allYears.map((year) => (
                <th colSpan={2} key={year} className="px-4 py-3 border border-gray-300 text-center">
                  Tahun {year}
                </th>
              ))}
            </tr>
            <tr className="bg-gray-100">
              {allYears.map(() =>
                semesters.map((sem) => (
                  <th key={sem} className="px-4 py-3 border border-gray-300 text-center">
                    Semester {sem}
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {mapels.map((mapel) => (
              <tr key={mapel} className="hover:bg-gray-50">
                <td rowSpan={allYears.length} className="px-4 py-3 border border-gray-300 font-semibold text-center">{mapel}</td>
                {allYears.map((year) => (
                  semesters.map((sem) => (
                    <td key={`${mapel}-${year}-${sem}`} className="px-4 py-3 border border-gray-300 text-center">
                      {groupedData[mapel][year]?.[sem] || "-"}
                    </td>
                  ))
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfileSiswa;
