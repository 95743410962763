import React from "react";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div className="flex justify-center  min-h-screen bg-blue-100">
      <div className="mt-10 mb-20">
        <div className=" flex justify-center items-center my-10">
          <h2 className="font-bold text-3xl">Admin Dashboard</h2>
        </div>
        <div className="flex justify-center items-center ">
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-y-10 gap-4 md:gap-8 ">
            <Link to={"/admin/listadmin"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">Admin</h1>
              </div>
            </Link>
            <Link to={"/admin/listguru"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">Guru</h1>
              </div>
            </Link>
            <Link to={"/admin/listsiswa"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">Siswa</h1>
              </div>
            </Link>
            <Link to={"/admin/ektrakulikuler"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">EktraKulikuler</h1>
              </div>
            </Link>
            <Link to={"/updatedatasekolah"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">Data Sekolah</h1>
              </div>
            </Link>
            <Link to={"/listp5"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">P5</h1>
              </div>
            </Link>
            <Link to={"/cpadmin"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">CP</h1>
              </div>
            </Link>
            <Link to={"/kelolaberita"}>
              <div className="bg-white  drop-shadow-lg h-40 w-40 md:h-60 md:w-60 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-125">
                <h1 className="text-xl md:text-2xl">Berita</h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
