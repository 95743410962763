// Home.js

import React from "react";
import Baner from "../pages/Baner";
import Berita from "../pages/Berita.js";
import Ekskul from "../pages/Ekskul";
import Foto from "../pages/Foto";
import Moto from "../pages/Moto";

const Home = () => {
  return (
    <div className="  min-h-screen bg-white">
      <Baner />
      <Moto />
      <Berita />
      <Foto />
      <Ekskul />
    </div>
  );
};

export default Home;
