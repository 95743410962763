import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FiMenu } from "react-icons/fi"; // Hamburger Icon
import { Link, useNavigate } from "react-router-dom";
import tuturi from "../asset/tuturi.png";

const Header = ({ role, handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [user, setUser] = useState({});
  const menuRef = useRef(null);
  const [schoolData, setSchoolData] = useState([]);
  const navigate = useNavigate();

  // Fetch school data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`
        );
        setSchoolData(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    fetchData();
  }, []);

  // Handle token and user profile
  useEffect(() => {
    const token = localStorage.getItem("token");
    setHasToken(!!token);

    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}api/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    }
  }, []);

  // Close menu when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle logout and reload
  const handleLogoutAndReload = () => {
    handleLogout();
    window.location.reload();
  };

  return (
    <div className="relative w-full z-50">
      <header className="bg-[#EE4D12] h-24 w-full">
        {/* Top Bar: Logout / Login */}
        <nav className="bg-[#F9A71A] h-9 py-2 px-5 flex justify-end items-center">
          {hasToken ? (
            <button
              onClick={handleLogoutAndReload}
              className="bg-red-500 px-4 rounded-full text-white hover:bg-red-700"
            >
              Logout
            </button>
          ) : (
            <div className="bg-red-500 px-4 rounded-full text-white hover:bg-red-700">
              <Link to="/login">Masuk</Link>
            </div>
          )}
        </nav>

        {/* Main Navbar */}
        <nav className="px-5 flex items-center justify-between lg:justify-start">
          {/* Logo and School Name */}
          <div className="flex items-center gap-2  lg:text-black font-bold text-xl lg:bg-white py-1.5 px-2 w-[1900px] lg:w-2/6">
            <img src={tuturi} alt="logo" className="h-full w-12" />
            {schoolData.map((school) => (
              <div key={school._id}>
                <Link to="/" className="text-white  text-[14px] md:text-md md:text-black lg:text-lg ">
                  {school.namasekolah}
                </Link>
              </div>
            ))}
          </div>

          {/* Navigation Menu (Desktop) */}

          <div className="flex justify-between items-center w-full">
            {/* Menu kiri */}
            <ul className="hidden lg:flex gap-8 items-center text-white font-bold ml-8">
              <li>
                <Link to="/">Beranda</Link>
              </li>
              <li>
                <Link to="/profilsekolah">Profil</Link>
              </li>
              <li>
                <Link to="/berita">Berita</Link>
              </li>
            </ul>

            {/* Menu kanan */}
            <ul
              ref={menuRef}
              className={`hidden lg:flex gap-8 justify-end items-center text-white font-bold ml-auto`}
            >
              {user.image && (
                <img
                  onClick={() => navigate(`/profile/${user._id}`)}
                  src={user.image}
                  alt="User"
                  className="h-10 w-10 rounded-full cursor-pointer"
                />
              )}
              {user.name && (
                <span
                  className="text-white"
                  onClick={() => navigate(`profile/${user._id}`)}
                >
                  {user.name}
                </span>
              )}

              {/* Role-based Links */}
              {role === "admin" && (
                <li>
                  <Link to="/admin">Admin Dashboard</Link>
                </li>
              )}
              {role === "guru" && (
                <li>
                  <Link to="/guru">Guru Dashboard</Link>
                </li>
              )}
              {role === "siswa" && (
                <li>
                  <Link to="/siswa">Siswa Dashboard</Link>
                </li>
              )}
            </ul>
          </div>
          {/* Hamburger Icon for Mobile */}
          <div className="lg:hidden ">
            <FiMenu
              size={30}
              className="text-white cursor-pointer"
              onClick={toggleMenu}
            />
          </div>
          {/* Mobile Dropdown Menu */}
          <ul
            ref={menuRef}
            className={`absolute right-0 top-24 w-48 bg-[#F9A71A] rounded-lg p-4 text-white text-md font-bold ${
              isMenuOpen ? "block" : "hidden"
            } lg:hidden`}
          >
            {user.image && (
              <img
                onClick={() => navigate(`/profile/${user._id}`)}
                src={user.image}
                alt="User"
                className="h-10 w-10 rounded-full"
              />
            )}
            {user.name && <span className="text-white">{user.name}</span>}
            <li>
              <Link to="/profilsekolah">Profil Sekolah</Link>
            </li>
            <hr className="border-t-2 border-white" />
            <li>
              <Link to="/berita">Berita</Link>
            </li>
            <hr className="border-t-2 border-white" />
            {/* Role-based Links */}
            {role === "admin" && (
              <li>
                <Link to="/admin">Admin Dashboard</Link>
              </li>
            )}
            {role === "guru" && (
              <li>
                <Link to="/guru">Guru Dashboard</Link>
              </li>
            )}
            {role === "siswa" && (
              <li>
                <Link to="/siswa">Siswa Dashboard</Link>
              </li>
            )}
          </ul>
        </nav>
      </header>
    </div>
  );
};

export default Header;
