import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"; // Import storage from your Firebase configuration
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Back from "../components/button/back";
import { storage } from "./FirebaseImage";

const AddUser = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    role: "",
    NISN: "",
    NIPD: "",
    NIP: "",
    statusPPH: "",
    jabatan: "",
    name: "",
    kelamin: "",
    TL: "",
    TGL: "",
    kelas: "",
    rombel: "",
    mapel: "",
    image: "",
  });

  const [file, setFile] = useState(null);
  const [usernameExists, setUsernameExists] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setUsernameExists(false);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!file) return "";
    const fileName = `${uuidv4()}-${file.name}`;
    const storageRef = ref(storage, `users/${fileName}`);

    try {
      // Upload file to Firebase Storage
      const uploadResult = await uploadBytes(storageRef, file);
      // Get download URL of the uploaded file
      const downloadURL = await getDownloadURL(uploadResult.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imageUrl = await handleImageUpload();
    if (imageUrl) {
      formData.image = imageUrl;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/auth/register`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("User added:", response.data);
      toast.success("User added successfully!");
      navigate(`/admin/add-user`);
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.msg === "User already exists"
      ) {
        setUsernameExists(true);
        toast.error(
          "Username already exists. Please use a different username."
        );
      } else {
        console.error("Error adding user:", error);
        toast.error("Error adding user. Please try again.");
      }
    }
  };

  return (
    <div className="grid justify-center min-h-screen bg-blue-100">
      <ToastContainer />
      <div>
        <div className="flex justify-center my-10">
          <h2 className="font-bold text-3xl">Add User</h2>
        </div>

        <div className="bg-white p-5 rounded-lg w-[400px] md:w-[600px]">
          <form
            onSubmit={handleSubmit}
            className="grid ring-1 ring-slate-500/20 rounded-md p-5 gap-y-5"
          >
            {usernameExists && (
              <div className="text-red-500">
                Username sudah digunakan. Silakan gunakan username lain.
              </div>
            )}
            <select
              name="role"
              value={formData.role}
              onChange={handleChange}
              required
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
            >
              <option value="">Pilih Role</option>
              <option value="admin">Admin</option>
              <option value="guru">Guru</option>
              <option value="siswa">Siswa</option>
            </select>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            />
            {formData.role === "siswa" && (
              <>
                <input
                  type="text"
                  name="NISN"
                  placeholder="NISN"
                  onChange={handleChange}
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                />
                <input
                  type="text"
                  name="NIPD"
                  placeholder="NIPD"
                  onChange={handleChange}
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                />
                <select
                  name="kelas"
                  value={formData.kelas}
                  onChange={handleChange}
                  required
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                >
                  <option value="">Pilih Kelas</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
                <select
                  name="rombel"
                  value={formData.rombel}
                  onChange={handleChange}
                  required
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                >
                  <option value="">Pilih Rombel</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                </select>
              </>
            )}
            {formData.role === "guru" && (
              <>
                <select
                  name="statusPPH"
                  value={formData.statusPPH}
                  onChange={handleChange}
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                >
                  <option value="">Pilih status</option>
                  <option value="PNS">PNS</option>
                  <option value="PPPK">PPPK</option>
                  <option value="Honorer">Honorer</option>
                </select>
                <input
                  type="text"
                  name="NIP"
                  placeholder="NIP"
                  onChange={handleChange}
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                />
                <select
                  name="jabatan"
                  value={formData.jabatan}
                  onChange={handleChange}
                  className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                >
                  <option value="">Pilih jabatan</option>
                  <option value="Kepala Sekolah">Kepala Sekolah</option>
                  <option value="Guru Kelas">Guru Kelas</option>
                  <option value="Guru Mapel">Guru Mapel</option>
                </select>
                {formData.jabatan === "Guru Kelas" && (
                  <>
                    <select
                      name="kelas"
                      value={formData.kelas}
                      onChange={handleChange}
                      required
                      className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                    >
                      <option value="">Pilih Kelas</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                    <select
                      name="rombel"
                      value={formData.rombel}
                      onChange={handleChange}
                      required
                      className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                    >
                      <option value="">Pilih Rombel</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                    </select>
                  </>
                )}
                {formData.jabatan === "Guru Mapel" && (
                  <>
                    <select
                      name="mapel"
                      value={formData.mapel}
                      onChange={handleChange}
                      className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
                      required
                    >
                      <option value="">Pilih Mata Pelajaran</option>
                      <option value="IPA">IPA</option>
                      <option value="IPS">IPS</option>
                      <option value="MTK">MTK</option>
                      <option value="BIndo">BIndo</option>
                      <option value="BIng">BIng</option>
                      <option value="PAI">PAI</option>
                      <option value="PKN">PKN</option>
                      <option value="IPAS">IPAS</option>
                      <option value="PJOK">PJOK</option>
                      <option value="MULOK">MULOK</option>
                      <option value="Senibudaya">Senibudaya</option>
                    </select>
                  </>
                )}
              </>
            )}
            <input
              type="text"
              name="name"
              placeholder="Nama Lengkap"
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            />
            <select
              name="kelamin"
              value={formData.kelamin}
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            >
              <option value="">Laki-laki/Perempuan</option>
              <option value="Laki-laki">Laki-laki</option>
              <option value="Perempuan">Perempuan</option>
            </select>
            <input
              type="text"
              name="TL"
              placeholder="Tempat Lahir"
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            />
            <input
              type="date"
              name="TGL"
              placeholder="Tanggal Lahir"
              onChange={handleChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
              required
            />
            <input
              type="file"
              name="image"
              onChange={handleFileChange}
              className="bg-transparent ring-1 ring-slate-500/20 py-2 px-2 rounded-lg"
            />
            <div className="flex justify-between items-center">
              <button
                type="submit"
                className="py-2 px-5 bg-indigo-600 text-white rounded-lg"
              >
                Add User
              </button>
            </div>
          </form>
          <div className="mx-5">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>

        </div>
      </div>
    </div>
  );
};

export default AddUser;
