import axios from "axios";
import React, { useEffect, useState } from "react";

const KURKUM = () => {
  const [tujuanPembelajaran, setTujuanPembelajaran] = useState([]);
  const [kelas, setKelas] = useState("");
  const [semester, setSemester] = useState("");
  const [mapel, setMapel] = useState("");
  const [existingTPData, setExistingTPData] = useState([]);
  const [savedMessage, setSavedMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [duplicateDataMessage, setDuplicateDataMessage] = useState("");

  useEffect(() => {
    fetchExistingTPData();
  }, []);

  const fetchExistingTPData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/tp`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExistingTPData(response.data);
    } catch (error) {
      console.error("Error fetching existing TP data:", error);
    }
  };

  const tambahTujuanPembelajaran = () => {
    setTujuanPembelajaran([...tujuanPembelajaran, ""]);
  };

  const handleInputChange = (index, event) => {
    const values = [...tujuanPembelajaran];
    values[index] = event.target.value;
    setTujuanPembelajaran(values);
  };

  const hapusTujuanPembelajaran = (index) => {
    const values = [...tujuanPembelajaran];
    values.splice(index, 1);
    setTujuanPembelajaran(values);
  };

  const handleSave = async () => {
    if (!kelas || !semester || !mapel || tujuanPembelajaran.some((tp) => !tp)) {
      setErrorMessage("Semua input wajib diisi.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const existingData = existingTPData.filter(
        (data) =>
          data.kelas === kelas &&
          data.semester === semester &&
          data.mapel === mapel
      );
      if (existingData.length > 0) {
        setDuplicateDataMessage(
          "Data dengan kelas, semester, dan mata pelajaran tersebut sudah ada."
        );
        setSavedMessage(""); // Reset saved message
        setErrorMessage(""); // Reset error message
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/tp`,
        {
          mapel,
          kelas,
          semester,
          TP: tujuanPembelajaran.map((tp) => ({ isiTP: tp })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("TP saved successfully:", response.data);
      fetchExistingTPData(); // Refresh existing TP data after saving
      setSavedMessage("Data berhasil disimpan.");
      setErrorMessage(""); // Reset error message
      setDuplicateDataMessage(""); // Reset duplicate data message
    } catch (error) {
      console.error("Error saving TP:", error);
      setSavedMessage("Gagal menyimpan data.");
    }
  };

  const renderInputs = () => {
    return tujuanPembelajaran.map((tp, index) => (
      <tr key={index}>
        <td>TP {index + 1}:</td>
        <td>
          <textarea
            type="text"
            value={tp}
            onChange={(e) => handleInputChange(index, e)}
            className="p-2 md:w-[500px] border border-slate-500 rounded-lg"
            placeholder={`Masukkan tujuan pembelajaran ${index + 1}`}
          />
        </td>
        <td>
          <button
            onClick={() => hapusTujuanPembelajaran(index)}
            className="bg-red-500 px-5 py-2 rounded-lg "
          >
            Hapus
          </button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="min-h-screen bg-blue-100 py-10">
      <div className="bg-white mx-5 p-5 rounded-lg drop-shadow-lg">
        <div className="flex justify-center py-5 ">
          <h1 className="font-bold text-2xl">Kurikulum Merdeka</h1>
        </div>
        <div className="grid gap-y-4 border border-slate-500 rounded-lg p-5 bg-white">
          {/* Pilihan Kelas */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Kelas:</label>
            <select
              value={kelas}
              onChange={(e) => setKelas(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Kelas</option>
              {[1, 2, 3, 4, 5, 6].map((kelas) => (
                <option key={kelas} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>

          {/* Pilihan Semester */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Semester:</label>
            <select
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Semester</option>
              {[1, 2].map((semester) => (
                <option key={semester} value={semester}>
                  {semester}
                </option>
              ))}
            </select>
          </div>

          {/* Pilihan Mata Pelajaran */}
          <div className="flex items-center">
            <label className="w-1/4 font-medium">Mata Pelajaran:</label>
            <select
              value={mapel}
              onChange={(e) => setMapel(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Mata Pelajaran</option>
              {[
                "MTK",
                "BIndo",
                "IPA",
                "IPS",
                "BIng",
                "IPAS",
                "PKN",
                "PJOK",
                "MULOK",
                "PAI",
              ].map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>

          {/* Tabel Input */}
          <div className="flex justify-center mt-4">
            <table className="border border-slate-400 w-full text-sm text-center">
              <tbody>{renderInputs()}</tbody>
            </table>
          </div>

          {/* Tombol Tambah dan Pesan */}
          <div className="flex flex-col items-center gap-2 mt-5">
            <button
              onClick={tambahTujuanPembelajaran}
              className="bg-green-500 text-white rounded-lg px-5 py-2 hover:bg-green-600"
            >
              Tambah TP
            </button>
            {savedMessage && <p className="text-green-600">{savedMessage}</p>}
            {duplicateDataMessage && <p className="text-orange-500">{duplicateDataMessage}</p>}
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </div>
        </div>

        <div className="flex justify-center my-5">
          <button
            onClick={handleSave}
            className="bg-blue-400 px-5 py-2 rounded-lg"
          >
            Simpan TP
          </button>
        </div>

      </div>
    </div>
  );
};

export default KURKUM;
