import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RaporMapel from "./RaporMapel";

const NilaiRaporList = ({ mapel }) => {
  const [nilaiRapor, setNilaiRapor] = useState([]);
  const [tp, setTP] = useState([]);
  const [tahun, setTahun] = useState("");
  const [semester, setSemester] = useState("");
  const [kelas, setKelas] = useState("");
  const [rombel, setRombel] = useState("");
  const [editingStudent, setEditingStudent] = useState(null);
  const [showRaporMapel, setShowRaporMapel] = useState(false);

  useEffect(() => {
    const fetchNilaiRaporByMapel = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilaiRapor/mapel/${mapel}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              tahun,
              semester,
              kelas,
              rombel,
            },
          }
        );
        console.log("Nilai Rapor:", response.data);
        const formattedNilaiRapor = response.data.map((item) => ({
          ...item,
          nilai: item.nilai.map((nilai) => ({
            ...nilai,
            CPtercapi: nilai.CPtercapi || [],
            CPditingkatkan: nilai.CPditingkatkan || [],
          })),
        }));
        setNilaiRapor(formattedNilaiRapor);
      } catch (error) {
        console.error("Error fetching Nilai Rapor data:", error);
        setNilaiRapor([]);
      }
    };

    const fetchTPByMapel = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/tp/mapel/${mapel}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              semester,
              kelas,
            },
          }
        );
        console.log("TP:", response.data);
        setTP(response.data);
      } catch (error) {
        console.error("Error fetching TP data:", error);
        setTP([]);
      }
    };

    fetchNilaiRaporByMapel();
    fetchTPByMapel();
  }, [mapel, tahun, semester, kelas, rombel]);

  const handleTPChange = (studentId, type, checked, isiTP) => {
    setEditingStudent((prevEditingStudent) => {
      if (!prevEditingStudent) return null;

      const updatedStudent = {
        ...prevEditingStudent,
        [type]: checked
          ? [...prevEditingStudent[type], isiTP]
          : prevEditingStudent[type].filter((tp) => tp !== isiTP),
      };

      return updatedStudent;
    });
  };

  const toggleRaporMapel = () => {
    setShowRaporMapel((prevShowRaporMapel) => !prevShowRaporMapel);
  };

  const openEditModal = (studentId) => {
    const studentToEdit = nilaiRapor
      .flatMap((item) => item.nilai)
      .find((nilai) => nilai._id === studentId);
    setEditingStudent(studentToEdit);
  };

  const closeEditModal = () => {
    setEditingStudent(null);
  };

  const saveEditedStudent = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!editingStudent || !editingStudent._id) {
        console.error(
          "Invalid editingStudent object or studentId is not defined"
        );
        return;
      }

      const payload = {
        CPtercapi: editingStudent.CPtercapi || [],
        CPditingkatkan: editingStudent.CPditingkatkan || [],
      };

      console.log("Updating student with payload:", payload);

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/nilaiRapor/${editingStudent._id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Data updated successfully:", response.data);
      toast.success("Data siswa berhasil diperbarui!");

      closeEditModal();
    } catch (error) {
      if (error.response) {
        console.error("Error updating student data:", error.response.data);
      } else {
        console.error("Error updating student data:", error.message);
      }
      toast.error("Terjadi kesalahan saat memperbarui data siswa!");
    }
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };

  const filteredNilaiRapor = nilaiRapor.filter(
    (item) =>
      (!tahun || item.tahun === tahun) &&
      (!semester || item.semester === semester) &&
      (!kelas || item.kelas === kelas) &&
      (!rombel || item.rombel === rombel)
  );

  const filteredTP = tp.filter(
    (item) =>
      (!semester || item.semester === semester) &&
      (!kelas || item.kelas === kelas)
  );

  return (
    <div className="bg-white my-10 rounded-lg p-5 drop-shadow-lg w-auto mx-5">
      <ToastContainer />
      <div className="flex justify-center">
        <h2 className="font-bold text-2xl">Daftar Nilai Rapor</h2>
      </div>

      <div>
        <div className="grid gap-4 border border-slate-500 p-4 rounded-lg bg-white shadow-md">
          {/* Tahun */}
          <div className="flex items-center gap-3">
            <label className="w-1/3 font-medium">Tahun:</label>
            <input
              type="text"
              value={tahun}
              onChange={(e) => setTahun(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            />
          </div>

          {/* Semester */}
          <div className="flex items-center gap-3">
            <label className="w-1/3 font-medium">Semester:</label>
            <select
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
              className="flex-1 border border-slate -500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Semester</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>

          {/* Kelas */}
          <div className="flex items-center gap-3">
            <label className="w-1/3 font-medium">Kelas:</label>
            <select
              value={kelas}
              onChange={(e) => setKelas(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Kelas</option>
              {[1, 2, 3, 4, 5, 6].map((kelas) => (
                <option key={kelas} value={kelas}>
                  {kelas}
                </option>
              ))}
            </select>
          </div>

          {/* Rombel */}
          <div className="flex items-center gap-3">
            <label className="w-1/3 font-medium">Rombel:</label>
            <select
              value={rombel}
              onChange={(e) => setRombel(e.target.value)}
              className="flex-1 border border-slate-500 rounded-lg px-3 py-2"
            >
              <option value="">Pilih Rombel</option>
              <option value="A">A</option>
              <option value="B">B</option>
            </select>
          </div>
        </div>

      </div>

      <div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3 mb-5"
          onClick={toggleRaporMapel}
        >
          {showRaporMapel ? "Cancel" : "Add Rapor"}
        </button>
      </div>
      <div>{showRaporMapel && <RaporMapel mapel={mapel} />}</div>
      <div className="flex justify-center my-5">
        <h3 className="font-bold">Nilai Mata Pelajaran: {mapel}</h3>
      </div>

      <div className="overflow-x-auto w-[320px] overflow-y-auto h-[400px] sm:h-auto sm:w-auto md:w-auto">
        {filteredNilaiRapor.length > 0 ? (
          <table className="min-w-full overflow-x-scroll">
            <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
              <tr>
                <th className="p-2">Nama Siswa</th>
                <th className="p-2">Nilai Akhir</th>
                <th className="p-2">CP yang terpenuhi</th>
                <th className="p-2">CP Perlu peningkatan</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="border border-slate-500">
              {filteredNilaiRapor.map((item) =>
                item.nilai.map((nilai) => (
                  <tr key={nilai._id}>
                    <td className="p-2">{nilai.name}</td>
                    <td className="p-2">{nilai.finalScore}</td>
                    <td className="p-2">
                      {filteredTP.map((tpItem) =>
                        tpItem.TP.map((isi) => (
                          <div
                            key={`${nilai._id}_${tpItem._id}_terpenuhi_${isi._id}`}
                          >
                            <input
                              type="checkbox"
                              id={`${nilai._id}_${tpItem._id}_terpenuhi_${isi._id}`}
                              name={`${nilai._id}_${tpItem._id}_terpenuhi`}
                              value={isi.isiTP}
                              onChange={(e) =>
                                handleTPChange(
                                  nilai._id,
                                  "CPtercapi",
                                  e.target.checked,
                                  isi.isiTP
                                )
                              }
                              checked={nilai.CPtercapi.includes(isi.isiTP)}
                              disabled={!!editingStudent}
                              className="mr-1"
                            />
                            <label
                              htmlFor={`${nilai._id}_${tpItem._id}_terpenuhi_${isi._id}`}
                            >
                              {truncateText(isi.isiTP, 6)}
                            </label>
                          </div>
                        ))
                      )}
                    </td>
                    <td className="p-2">
                      {filteredTP.map((tpItem) =>
                        tpItem.TP.map((isi) => (
                          <div
                            key={`${nilai._id}_${tpItem._id}_peningkatan_${isi._id}`}
                          >
                            <input
                              type="checkbox"
                              id={`${nilai._id}_${tpItem._id}_peningkatan_${isi._id}`}
                              name={`${nilai._id}_${tpItem._id}_peningkatan`}
                              value={isi.isiTP}
                              onChange={(e) =>
                                handleTPChange(
                                  nilai._id,
                                  "CPditingkatkan",
                                  e.target.checked,
                                  isi.isiTP
                                )
                              }
                              checked={nilai.CPditingkatkan.includes(isi.isiTP)}
                              disabled={!!editingStudent}
                              className="mr-1"
                            />
                            <label
                              htmlFor={`${nilai._id}_${tpItem._id}_peningkatan_${isi._id}`}
                            >
                              {truncateText(isi.isiTP, 6)}
                            </label>
                          </div>
                        ))
                      )}
                    </td>
                    <td>
                      <div>
                        <button
                          className="bg-blue-400 rounded-lg p-2"
                          onClick={() => openEditModal(nilai._id)}
                          disabled={!!editingStudent}
                        >
                          Edit
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <p className="text-center">
            Tidak ada data Nilai Rapor yang ditemukan untuk filter yang
            diterapkan.
          </p>
        )}
      </div>

      {editingStudent && (
        <div className="fixed top-0 left-0 w-full h- bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full ">
            <h2 className="text-lg font-bold mb-4">Edit Data Siswa</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Nama Siswa
              </label>
              <input
                type="text"
                value={editingStudent.name}
                onChange={(e) =>
                  setEditingStudent({
                    ...editingStudent,
                    name: e.target.value,
                  })
                }
                className="border border-gray-300 rounded-lg px-3 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Nilai Akhir
              </label>
              <input
                type="text"
                value={editingStudent.finalScore}
                onChange={(e) =>
                  setEditingStudent({
                    ...editingStudent,
                    finalScore: e.target.value,
                  })
                }
                className="border border-gray-300 rounded-lg px-3 py-2 w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                CP yang terpenuhi
              </label>
              {filteredTP.map((tpItem) =>
                tpItem.TP.map((isi) => (
                  <div
                    key={`${editingStudent._id}_${tpItem._id}_terpenuhi_${isi._id}`}
                  >
                    <input
                      type="checkbox"
                      id={`${editingStudent._id}_${tpItem._id}__terpenuhi_${isi._id}`}
                      name={`${editingStudent._id}_${tpItem._id}_terpenuhi`}
                      value={isi.isiTP}
                      onChange={(e) =>
                        handleTPChange(
                          editingStudent._id,
                          "CPtercapi",
                          e.target.checked,
                          isi.isiTP
                        )
                      }
                      checked={editingStudent.CPtercapi.includes(isi.isiTP)}
                      className="mr-1"
                    />
                    <label
                      htmlFor={`${editingStudent._id}_${tpItem._id}__terpenuhi_${isi._id}`}
                    >
                      {truncateText(isi.isiTP, 6)}
                    </label>
                  </div>
                ))
              )}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                CP Perlu peningkatan
              </label>
              {filteredTP.map((tpItem) =>
                tpItem.TP.map((isi) => (
                  <div
                    key={`${editingStudent._id}_${tpItem._id}_peningkatan_${isi._id}`}
                  >
                    <input
                      type="checkbox"
                      id={`${editingStudent._id}_${tpItem._id}_peningkatan_${isi._id}`}
                      name={`${editingStudent._id}_${tpItem._id}_peningkatan`}
                      value={isi.isiTP}
                      onChange={(e) =>
                        handleTPChange(
                          editingStudent._id,
                          "CPditingkatkan",
                          e.target.checked,
                          isi.isiTP
                        )
                      }
                      checked={editingStudent.CPditingkatkan.includes(
                        isi.isiTP
                      )}
                      className="mr-1"
                    />
                    <label
                      htmlFor={`${editingStudent._id}_${tpItem._id}_peningkatan_${isi._id}`}
                    >
                      {truncateText(isi.isiTP, 6)}
                    </label>
                  </div>
                ))
              )}
            </div>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={saveEditedStudent}
              >
                Simpan
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                onClick={closeEditModal}
              >
                Batal
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NilaiRaporList;
