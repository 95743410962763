import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../components/button/back";

const InputNilaiManual = () => {
  const { questionId } = useParams();
  const [siswas, setSiswas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    kelas: "",
    rombel: "",
    mapel: "",
    jenis: "",
    tahun: "",
    semester: "",
  });

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found. Please login again.");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setFormData({
          kelas: response.data.kelas,
          rombel: response.data.rombel,
          mapel: response.data.mapel,
          jenis: response.data.jenis,
        });

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchSiswas = async () => {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const filteredSiswas = response.data.filter(
          (user) =>
            user.role === "siswa" &&
            user.kelas === formData.kelas &&
            user.rombel === formData.rombel
        );
        setSiswas(filteredSiswas);
      } catch (error) {
        console.error("Error fetching siswas:", error);
      }
    };

    fetchQuestion();
    fetchSiswas();
  }, [questionId, formData.kelas, formData.rombel]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNilaiChange = (id, value) => {
    setSiswas((prevSiswas) =>
      prevSiswas.map((siswa) =>
        siswa._id === id ? { ...siswa, nilai: value } : siswa
      )
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    if (!token) {
      alert("No token found. Please login again.");
      return;
    }

    try {
      const existingRaporResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}api/nilai/mapel/${formData.mapel}/question/${questionId}/kelas/${formData.kelas}/rombel/${formData.rombel}/tahun/${formData.tahun}/semester/${formData.semester}/jenis/${formData.jenis}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (existingRaporResponse.data.length > 0) {
        alert("Data untuk nilai pada soal ini telah disimpan !!!!");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/nilai`,
        {
          ...formData,
          questionId,
          nilai: siswas.map((siswa) => ({
            studentId: siswa._id,
            name: siswa.name,
            nilaiTotal: siswa.nilai || 0,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        alert("Data telah disimpan");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert("Internal Server Error: Please try again later.");
      } else {
        console.error("Error saving data:", error);
        setError("Error saving data");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-blue-100 py-10">
      <div className="bg-white mx-5 px-5 rounded-lg drop-shadow-lg">
        <div className="flex justify-center py-5 ">
          <h1 className="font-bold text-2xl">Input Nilai Manual</h1>
        </div>

        <form onSubmit={handleFormSubmit}>
          <div className="grid border-2 border-black py-4 px-6 rounded-lg mb-5 gap-4 text-sm md:text-base">
            {/* Kelas */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Kelas:</label>
              <input
                type="text"
                name="kelas"
                value={formData.kelas}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              />
            </div>
            {/* Rombel */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Rombel:</label>
              <input
                type="text"
                name="rombel"
                value={formData.rombel}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              />
            </div>
            {/* Mapel */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Mapel:</label>
              <input
                type="text"
                name="mapel"
                value={formData.mapel}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              />
            </div>
            {/* Jenis */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Jenis:</label>
              <input
                type="text"
                name="jenis"
                value={formData.jenis}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              />
            </div>
            {/* Tahun */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Tahun:</label>
              <input
                type="text"
                name="tahun"
                value={formData.tahun}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              />
            </div>
            {/* Semester */}
            <div className="grid grid-cols-3 items-center">
              <label className="font-medium">Semester:</label>
              <select
                name="semester"
                value={formData.semester}
                onChange={handleInputChange}
                className="border border-slate-500 rounded-lg px-3 py-1 col-span-2"
              >
                <option value="">Pilih semester</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>
          </div>


          <div>
            <div className="flex justify-center py-5">
              <h2 className="font-bold text-2xl">All Users</h2>
            </div>

            <table className="min-w-full divide-y divide-gray-200 bg-white">
              <thead className="bg-gradient-to-tr from-cyan-600 to-cyan-400">
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Nilai</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 border border-slate-500">
                {siswas.map((user, index) => (
                  <tr key={user._id}>
                    <div className="flex justify-center">
                      <td>{index + 1}</td>
                    </div>

                    <td>{user.name}</td>
                    <div className="flex justify-center">
                      <td>
                        <input
                          type="number"
                          value={user.nilai || ""}
                          onChange={(e) =>
                            handleNilaiChange(user._id, e.target.value)
                          }
                          className="border border-slate-500 rounded-lg px-2 ml-3"
                        />
                      </td>
                    </div>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center py-10">
            <button
              type="submit"
              className="bg-blue-400 px-5 py-3 rounded-lg text-white hover:bg-blue-600"
            >
              Submit
            </button>
          </div>
          <div className="flex justify-center pb-10">
            <Link to={`/guru/elearning-guru/${formData.mapel}`}>
              <Back />
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InputNilaiManual;
