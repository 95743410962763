import axios from "axios";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { storage } from "./FirebaseImage"; // Adjust the path as needed
import { FaArrowRight } from "react-icons/fa";

const Ekskul = () => {
  const [ekskuls, setEkskuls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEkskuls = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/ekskul`
        );
        const data = response.data;

        // Fetch image URLs from Firebase Storage
        const ekskulWithImages = await Promise.all(
          data.map(async (ekskul) => {
            if (ekskul.Gambar) {
              const imageRef = ref(storage, `${ekskul.Gambar}`);
              const imageUrl = await getDownloadURL(imageRef);
              return { ...ekskul, imageUrl };
            }
            return ekskul;
          })
        );

        setEkskuls(ekskulWithImages);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchEkskuls();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Slice the array to get the first 3 items
  const displayedEkskuls = ekskuls.slice(0, 3);

  return (
    <div className="bg-gradient-to-br from-gray-100 to-gray-50 min-h-screen">
      <div className="text-center py-10">
        <h1 className="text-4xl font-bold text-gray-800 relative inline-block">
          Ekskul
          <span className="absolute w-full h-1 bg-blue-400 rounded-full bottom-0 left-0"></span>
        </h1>
      </div>

      {displayedEkskuls.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 px-10 pb-10">
          {displayedEkskuls.map((ekskul) => (
            <div
              key={ekskul._id}
              className="rounded-lg shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl bg-white"
            >
              {/* Gambar Ekskul */}
              <div className="overflow-hidden rounded-t-lg">
                {ekskul.imageUrl && (
                  <img
                    src={ekskul.imageUrl}
                    alt={ekskul.Judul}
                    className="w-full h-48 object-cover"
                  />
                )}
              </div>

              {/* Konten Ekskul */}
              <div className="p-5">
                <h2 className="font-semibold text-gray-800 text-lg mb-3">{ekskul.Judul}</h2>
                <button
                  className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-lg shadow-md transition duration-150 hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500"
                  onClick={() => navigate(`/ekskul-satu/${ekskul._id}`)}
                >
                  Lihat Informasi
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center text-gray-600">No ekskul found</div>
      )}

      <div className="text-center pb-10">
        <Link to={"/ekskul"}>
          <div className="flex justify-center items-center ">
            <button
              className="bg-gradient-to-r from-blue-400 to-blue-500 text-white px-6 py-3 rounded-lg hover:from-blue-500 hover:to-blue-600 transition-all flex items-center justify-center gap-2 duration-300 shadow-lg hover:shadow-xl"
            >
              Lihat Semua Ekskul
              <FaArrowRight className="text-lg" />
            </button>
          </div>

        </Link>
      </div>
    </div>
  );
};

export default Ekskul;
