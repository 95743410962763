import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Back from "../components/button/back";

const AnswerQuestion = () => {
  const { mapel, questionId } = useParams();
  const [answers, setAnswers] = useState([]);
  const [AnswerIsian, setAnswersIsian] = useState([]);
  const [inputValues, setInputValues] = useState([""])
  const [questionData, setQuestionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
    const fetchQuestion = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setQuestionData(response.data);

        const initialAnswers = response.data.question.map((q, questionIndex) => ({
          text: (inputValues[questionIndex] || []).join("|"), // Ambil jawaban per soal dan gabungkan
          type: "isian",
          bobot: q.bobot || 1,
          gambar: "",
        }));

        // Simpan data ke state AnswerIsian
        setAnswersIsian(initialAnswers);
        setAnswers(
          response.data.question.map((q) => ({
            text: "",
            type: q.type,
            bobot: q.bobot,
            gambar: "", // initialize gambar property for all answers
          }))
        );
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    const fetchAnswerStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/answers/status/${questionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.hasSubmitted) {
          console.log("SUDAAAH ADA")
          setSubmitted(true);
        }
      } catch (error) {
        console.error("Error fetching answer status:", error);
      }
    };

    fetchQuestion();
    fetchAnswerStatus();
  }, [questionId, inputValues]); // Hanya mendengarkan perubahan pada `questionId`
  useEffect(() => {
    console.log("submitted state berubah:", submitted);
  }, [submitted]);

  const handleInputChangeIsian = (nomorSoal, index, value) => {
    // Salin jawaban saat ini
    const updatedInputValues = { ...inputValues };

    // Ambil jawaban untuk soal tersebut, atau inisialisasi array kosong
    if (!updatedInputValues[nomorSoal]) {
      updatedInputValues[nomorSoal] = [];
    }
    updatedInputValues[nomorSoal][index] = value; // Perbarui jawaban pada indeks yang relevan

    // Perbarui state dengan jawaban yang diperbarui
    setInputValues(updatedInputValues);

    // Siapkan data untuk dikirim ke API
    const lowercasedAnswers = Object.entries(updatedInputValues).map(([nomor, jawaban]) => ({
      nomor: parseInt(nomor, 10), // Nomor soal sebagai integer
      text: jawaban.map((val) => val.toLowerCase()).join("|"), // Gabungkan jawaban dalam huruf kecil
      type: "isian",
      bobot: questionData?.question[parseInt(nomor, 10) - 1]?.bobot || 1, // Ambil bobot sesuai nomor soal
      gambar: "",
    }));

    setAnswersIsian(lowercasedAnswers);
  };

  const handleInputChange = (index, value, isCorrect = false) => {
    const newAnswers = [...answers];


    if (questionData.question[index].type === "multipleChoice") {
      newAnswers[index] = {
        text: value,
        gambar: answers[index].gambar,
        isCorrect,
        type: questionData.question[index].type,
        bobot: questionData.question[index].bobot,
      };
    } else {
      newAnswers[index] = {
        text: value,
        type: questionData.question[index].type,
        bobot: questionData.question[index].bobot,
        gambar: answers[index].gambar,
      };
    }


    setAnswers(newAnswers);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Cegah pengguna menekan berkali-kali jika `loading` masih true.

    setLoading(true); 
    try {
      const token = localStorage.getItem("token");

      // Gabungkan semua jawaban menjadi satu array tanpa mengubah struktur yang diharapkan
      const combinedAnswers = answers.map((answer, index) => {
        const isianText = AnswerIsian[index]?.text || "";

        const formattedAnswer = {
          text: answer.type === "isian" ? isianText : answer.text, // Gunakan jawaban dari `AnswerIsian` jika bertipe isian
          type: answer.type, // Tetap gunakan properti type tanpa perubahan
          bobot: answer.bobot, // Tetap gunakan bobot yang sudah ada
          gambar: answer.gambar || "", // Tetap sertakan properti gambar, bahkan jika kosong
          isCorrect: answer.isCorrect ? 1 : 0, // Kirim hanya jika nilai isCorrect bukan 0
        };

        console.log(`Jawaban ke-${index + 1}:`, formattedAnswer); // Log setiap jawaban individu
        return formattedAnswer;
      });

      console.log("Data yang akan dikirim:", combinedAnswers);

      // Kirim ke server
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/answers`,
        { questionId, answers: combinedAnswers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Jawaban berhasil dikirim!");
      setSubmitted(true);
      navigate(`/siswa/elearning-siswa/${mapel}`);
    } catch (error) {
      console.error("Error submitting answer:", error);
    }finally {
      setLoading(false); // Nonaktifkan loading setelah proses selesai
    }
  };

  if (!questionData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid min-h-screen bg-blue-100">

      <div className="my-10 w-screen px-4">
        <div className="flex justify-center items-center my-10">
          <h2 className="font-bold text-3xl">
            {questionData.jenis} - {questionData.mapel}
          </h2>
        </div>
        <div className="flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg w-[400px] md:w-[600px]">
            <form onSubmit={handleSubmit}>
              {questionData.question.map((q, index) => (
                <div key={q._id} className="mb-4">
                  <p className="text-xl mb-2">{`${index + 1}. ${q.text}`}</p>
                  {q.gambar && (
                    <img
                      src={`${q.gambar}`}
                      alt="Question"
                      className="mb-4 w-[400px] h-[200px]"
                    />
                  )}
                  {q.type === "multipleChoice" ? (
                    <div>
                      {q.options.map((option, idx) => (
                        <div key={idx} className="mb-2">
                          <input
                            type="radio"
                            id={`question${index}_option${idx}`}
                            name={`question${index}`}
                            value={option.text}
                            onChange={() =>
                              handleInputChange(index, option.text, option.isCorrect)
                            }
                            disabled={submitted}
                          />
                          <label
                            htmlFor={`question${index}_option${idx}`}
                            className="ml-2"
                          >
                            {option.text}
                          </label>
                          {option.gambar && (
                            <img
                              src={`${option.gambar}`}
                              alt="Option"
                              className="ml-2 w-[100px] h-[100px] rounded"
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  ) : q.type === "isian" ? (
                    <div>
                      <p className="mb-2">Jawaban Anda:</p>
                      {Array.from({ length: q.keyJawabanBenar }).map((_, idx) => (
                        <div key={idx} className="mb-2 flex items-center">
                          <input
                            type="text"
                            className="w-full p-2 border border-slate-500 rounded-lg"
                            value={inputValues[index]?.[idx] || ""} // Ambil nilai sesuai nomor soal dan jawaban
                            onChange={(e) =>
                              handleInputChangeIsian(index, idx, e.target.value)
                            } // Handle perubahan input
                            placeholder={`Jawaban ${idx + 1}`}
                            disabled={submitted} // Nonaktifkan jika sudah disubmit
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <textarea
                      className="w-full p-2 border border-slate-500 rounded-lg"
                      rows="5"
                      value={answers[index]?.text || ""}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      required
                      disabled={submitted}
                    />
                  )}
                </div>
              ))}

                <div className="flex justify-center mt-4">
                  {!submitted && (
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                      disabled={loading}
                    >
                      Submit Answer
                    </button>
                  )}
                  {submitted && (
                    <p>Anda sudah mengirimkan jawaban untuk soal ini.</p>
                  )}
                </div>
              
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-10">
        <Link to={`/siswa/elearning-siswa/${mapel}`}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default AnswerQuestion;
