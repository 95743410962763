import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../components/button/back";

const EkskulSatu = () => {
  const { id } = useParams(); // Get the ID from the URL
  const [ekskul, setEkskul] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEkskulById = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/ekskul/${id}`
        );
        setEkskul(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch the extracurricular activity.");
        setLoading(false);
      }
    };

    fetchEkskulById();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div></div>
      <div className="mx-auto max-w-3xl p-5 bg-white shadow-lg rounded-lg">
        <div className="flex justify-center my-3">
          <h1 className="font-bold text-2xl uppercase">Ektrakulikuler</h1>
        </div>
        <h1 className="text-2xl font-bold mb-4">{ekskul?.Judul}</h1>
        {ekskul?.Gambar && (
          <img
            src={ekskul?.Gambar}
            alt={ekskul?.Judul}
            className="w-full h-64 object-cover mb-4 rounded"
          />
        )}
        <p className="text-gray-700">{ekskul?.Deskripsi}</p>
      </div>
      <div className="flex justify-center pb-10">
        <Link to={"/ekskul"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EkskulSatu;
