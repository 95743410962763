import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Back from "../button/back";

const DataSekolah = () => {
  const { id } = useParams(); // Mengambil id dari params URL
  const navigate = useNavigate(); // Menggunakan hook useNavigate untuk navigasi
  const [schoolData, setSchoolData] = useState({
    namasekolah: "",
    NPSN: "",
    alamat: "",
    ttDsekolah: "",
    kodePos: "",
    Kec: "",
    kab: "",
    prov: "",
    statusSekolah: "",
    JenjangPendidikan: "",
    naungan: "",
    noSKpendiri: "",
    tglSkPendiri: "",
    noSkOprasional: "",
    Akreditasi: "",
    noSkAkreditasi: "",
    tglSkAkreditasi: "",
    noSertifikatIOS: "",
    fax: "",
    email: "",
    website: "",
    visi: [],
    misi: [],
  });

  useEffect(() => {
    // Jika id ada, berarti mode edit, maka fetch data sekolah berdasarkan id
    if (id) {
      const fetchSchoolData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/datasekolah/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setSchoolData(response.data);
        } catch (error) {
          console.error("Error fetching school data:", error);
          toast.error("Error fetching school data");
        }
      };

      fetchSchoolData();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "visi" || name === "misi") {
      // Jika mengubah visi atau misi, perlu menangani perubahan dalam array
      const { index, field } = e.target.dataset;
      const updatedData = [...schoolData[name]];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value,
      };
      setSchoolData({ ...schoolData, [name]: updatedData });
    } else {
      // Untuk perubahan data sekolah biasa
      setSchoolData({ ...schoolData, [name]: value });
    }
  };

  const handleAddVisiMisi = (e) => {
    e.preventDefault();
    const { name } = e.target.dataset;
    setSchoolData({
      ...schoolData,
      [name]: [...schoolData[name], { isiVisi: "" }],
    });
  };

  const handleRemoveVisiMisi = (e, index) => {
    e.preventDefault();
    const { name } = e.target.dataset;
    const updatedData = [...schoolData[name]];
    updatedData.splice(index, 1);
    setSchoolData({ ...schoolData, [name]: updatedData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      // Jika id ada, maka mode edit dan gunakan PUT untuk update
      if (id) {
        await axios.put(
          `https://backend-sd-2.vercel.app/api/datasekolah/${id}`,
          schoolData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        toast.success("Data sekolah berhasil diperbarui");
      } else {
        // Jika id tidak ada, maka mode tambah dan gunakan POST untuk create baru
        await axios.post(
          `https://backend-sd-2.vercel.app/api/datasekolah`,
          schoolData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        toast.success("Data sekolah berhasil ditambahkan");
      }
      navigate("/datasekolah"); // Menggunakan navigate untuk kembali ke halaman /datasekolah setelah berhasil update atau tambah data
    } catch (error) {
      console.error("Error updating or adding school data:", error);
      toast.error("Error updating or adding school data");
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-8 bg-slate-400/50 px-5 my-5 rounded-lg drop-shadow-lg">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">
        {id ? "Update" : "Tambah"} Data Sekolah
      </h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1">Nama Sekolah</label>
          <input
            type="text"
            name="namasekolah"
            value={schoolData.namasekolah}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">NPSN</label>
          <input
            type="text"
            name="NPSN"
            value={schoolData.NPSN}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Alamat</label>
          <input
            type="text"
            name="alamat"
            value={schoolData.alamat}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">ttDsekolah</label>
          <input
            type="text"
            name="ttDsekolah"
            value={schoolData.ttDsekolah}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Kode Pos</label>
          <input
            type="text"
            name="kodePos"
            value={schoolData.kodePos}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Kecamatan</label>
          <input
            type="text"
            name="Kec"
            value={schoolData.Kec}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Kabupaten</label>
          <input
            type="text"
            name="kab"
            value={schoolData.kab}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Provinsi</label>
          <input
            type="text"
            name="prov"
            value={schoolData.prov}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Status Sekolah</label>
          <input
            type="text"
            name="statusSekolah"
            value={schoolData.statusSekolah}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Jenjang Pendidikan</label>
          <input
            type="text"
            name="JenjangPendidikan"
            value={schoolData.JenjangPendidikan}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Naungan</label>
          <input
            type="text"
            name="naungan"
            value={schoolData.naungan}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">No SK Pendirian</label>
          <input
            type="text"
            name="noSKpendiri"
            value={schoolData.noSKpendiri}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Tanggal SK Pendirian</label>
          <input
            type="date"
            name="tglSkPendiri"
            value={schoolData.tglSkPendiri}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">No SK Operasional</label>
          <input
            type="text"
            name="noSkOprasional"
            value={schoolData.noSkOprasional}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Akreditasi</label>
          <input
            type="text"
            name="Akreditasi"
            value={schoolData.Akreditasi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">No SK Akreditasi</label>
          <input
            type="text"
            name="noSkAkreditasi"
            value={schoolData.noSkAkreditasi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Tanggal SK Akreditasi</label>
          <input
            type="date"
            name="tglSkAkreditasi"
            value={schoolData.tglSkAkreditasi}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">No Sertifikat ISO</label>
          <input
            type="text"
            name="noSertifikatIOS"
            value={schoolData.noSertifikatIOS}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Fax</label>
          <input
            type="text"
            name="fax"
            value={schoolData.fax}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Email</label>
          <input
            type="email"
            name="email"
            value={schoolData.email}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Website</label>
          <input
            type="text"
            name="website"
            value={schoolData.website}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
        <div>
          <label className="block mb-1">Visi</label>
          {schoolData.visi.map((visi, index) => (
            <div key={index} className="flex mb-2">
              <input
                type="text"
                name="visi"
                data-index={index}
                data-field="isiVisi"
                value={visi.isiVisi}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <button
                type="button"
                data-name="visi"
                onClick={(e) => handleRemoveVisiMisi(e, index)}
                className="ml-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          ))}
          <button
            type="button"
            data-name="visi"
            onClick={handleAddVisiMisi}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
          >
            Tambah Visi
          </button>
        </div>
        <div>
          <label className="block mb-1">Misi</label>
          {schoolData.misi.map((misi, index) => (
            <div key={index} className="flex mb-2">
              <input
                type="text"
                name="misi"
                data-index={index}
                data-field="isiMisi"
                value={misi.isiMisi}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
              />
              <button
                type="button"
                data-name="misi"
                onClick={(e) => handleRemoveVisiMisi(e, index)}
                className="ml-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700"
              >
                Hapus
              </button>
            </div>
          ))}
          <button
            type="button"
            data-name="misi"
            onClick={handleAddVisiMisi}
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-700"
          >
            Tambah Misi
          </button>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
        >
          {id ? "Update" : "Tambah"} Data Sekolah
        </button>
      </form>
      <div className="flex justify-center my-10">
        <Link to={"/updatedatasekolah"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default DataSekolah;
