import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import Back from "../button/back";

const fieldLabels = {
  BBKTYMEBM:
    "Beriman, Bertakwa Kepada Tuhan Yang Maha Esa, dan Berakhlak Mulia",
  BerkebhinekaanGlobal: "Berkebhinekaan Global",
  BergotongRoyong: "Bergotong Royong",
  Mandiri: "Mandiri",
  BernalarKritis: "Bernalar Kritis",
  Kreatif: "Kreatif",
};

const subFieldLabels = {
  akhlakberagama: "Akhlak beragama",
  AkhlakPribadi: "Akhlak Pribadi",
  akhlakkepadamanusia: "Akhlak kepada manusia",
  akhlakkepadaalam: "Akhlak kepada alam",
  akhlakbernegara: "Akhlak bernegara",
  mengenaldanmenghargaibudaya: "Mengenal dan menghargai budaya",
  komunikasidaninteraksiantarbudaya: "Komunikasi dan interaksi antarbudaya",
  refleksidanbertanggungjawabterhadappengalamankebinekaan:
    "Refleksi dan bertanggungjawab terhadap pengalaman kebinekaan",
  BerkeadilanSosial: "Berkeadilan Sosial",
  kolaborasi: "Kolaborasi",
  kepedulian: "Kepedulian",
  Berbagi: "Berbagi",
  Pemahamandiridansituasiyangdihadapi:
    "Pemahamandiri dan situasi yang dihadapi",
  RegulasiDiri: "Regulasi Diri",
  memperolehdanmemprosesinformasidangagasan:
    "Memperoleh dan memproses informasi dan gagasan",
  menganalisisdanmengevaluasipenalarandanprosedurnya:
    "Menganalisis dan mengevaluasi penalaran dan prosedurnya",
  Elemenrefleksipemikirandanprosesberpikir:
    "Refleksi pemikiran dan proses berpikir",
  menghasilkangagasanyangorisina: "Menghasilkan gagasan yang orisina",
  memilikikeluwesanberpikirdalammencarialternatifsolusipermasalahan:
    "Memiliki keluwesan berpikir dalam mencari alternatif solusi permasalahan",
};

const InputdataP5 = () => {
  const [formData, setFormData] = useState({
    kelas: "1",
    fase: "",
    BBKTYMEBM: {
      akhlakberagama: [""],
      AkhlakPribadi: [""],
      akhlakkepadamanusia: [""],
      akhlakkepadaalam: [""],
      akhlakbernegara: [""],
    },
    BerkebhinekaanGlobal: {
      mengenaldanmenghargaibudaya: [""],
      komunikasidaninteraksiantarbudaya: [""],
      refleksidanbertanggungjawabterhadappengalamankebinekaan: [""],
      BerkeadilanSosial: [""],
    },
    BergotongRoyong: {
      kolaborasi: [""],
      kepedulian: [""],
      Berbagi: [""],
    },
    Mandiri: {
      Pemahamandiridansituasiyangdihadapi: [""],
      RegulasiDiri: [""],
    },
    BernalarKritis: {
      memperolehdanmemprosesinformasidangagasan: [""],
      menganalisisdanmengevaluasipenalarandanprosedurnya: [""],
      Elemenrefleksipemikirandanprosesberpikir: [""],
    },
    Kreatif: {
      menghasilkangagasanyangorisina: [""],
      memilikikeluwesanberpikirdalammencarialternatifsolusipermasalahan: [""],
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNestedArrayChange = (e, index, field, subField) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newArray = [...prevData[field][subField]];
      newArray[index] = value;
      return {
        ...prevData,
        [field]: {
          ...prevData[field],
          [subField]: newArray,
        },
      };
    });
  };

  const addNestedArrayField = (field, subField) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        ...prevData[field],
        [subField]: [...prevData[field][subField], ""],
      },
    }));
  };

  const removeNestedArrayField = (index, field, subField) => {
    setFormData((prevData) => {
      const newArray = [...prevData[field][subField]];
      newArray.splice(index, 1);
      return {
        ...prevData,
        [field]: {
          ...prevData[field],
          [subField]: newArray,
        },
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      kelas,
      fase,
      BBKTYMEBM,
      BerkebhinekaanGlobal,
      BergotongRoyong,
      Mandiri,
      BernalarKritis,
      Kreatif,
    } = formData;

    const data = [
      {
        BBKTYMEBM,
        BerkebhinekaanGlobal,
        BergotongRoyong,
        Mandiri,
        BernalarKritis,
        Kreatif,
      },
    ];

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/p5/all`,
        { kelas, fase, data },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      toast.success("Data berhasil disimpan");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Data telah tersedia");
      } else {
        toast.error("Data tidak berhasil disimpan");
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Input data P5</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Kelas:
          </label>
          <select
            name="kelas"
            value={formData.kelas}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value=""> pilih kelas</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Fase:
          </label>
          <select
            name="fase"
            value={formData.fase}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value="">pilih </option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>
        {Object.keys(fieldLabels).map((field) => (
          <div key={field}>
            <label className="block mb-2 text-lg font-bold text-gray-700">
              {fieldLabels[field]}:
            </label>
            {Object.keys(formData[field]).map((subField) => (
              <div key={subField} className="ml-10">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  {subFieldLabels[subField]}:
                </label>
                {formData[field][subField].map((value, index) => (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={value}
                      onChange={(e) =>
                        handleNestedArrayChange(e, index, field, subField)
                      }
                      className="w-full border border-gray-300 p-2 rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        removeNestedArrayField(index, field, subField)
                      }
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      Hapus
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => addNestedArrayField(field, subField)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  Tambah
                </button>
              </div>
            ))}
          </div>
        ))}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
      <ToastContainer />
      <div className="flex justify-center mt-5 mb-10">
        <Link to={"/listp5"}>
          <buton>
            <Back />
          </buton>
        </Link>
      </div>
    </div>
  );
};

export default InputdataP5;
