import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Back from "../components/button/back";
import JumlahNilai from "./JumlahNilai";
import KURKUMlist from "./KURKUMlist";
import NilaiRaporList from "./NilaiRaporList";
import Persen from "./Persen";
import TabelPersen from "./TabelPersen";

const ElearningGuru = () => {
  const { mapel } = useParams();
  const [questions, setQuestions] = useState([]);
  const [semester, setSemester] = useState("1");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = localStorage.getItem("token");
        const decoded = jwtDecode(token);
        const userId = decoded.user.id;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/questions/mapel/${mapel}/user/${userId}?semester=${semester}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setQuestions(response.data);
        console.log("data : ", response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    if (mapel) {
      fetchQuestions();
    }
  }, [mapel, semester]);

  const handleToggle = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}api/questions/${id}/toggleStatus`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setQuestions((prevQuestions) =>
        prevQuestions.map((question) =>
          question._id === id
            ? { ...question, status: response.data.status }
            : question
        )
      );
    } catch (error) {
      console.error("Error toggling question status:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}api/questions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions((prevQuestions) =>
        prevQuestions.filter((question) => question._id !== id)
      );
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  const handleSemesterChange = (e) => {
    setSemester(e.target.value);
  };

  return (
    <div className="grid justify-center min-h-screen bg-blue-100">
      <div className="w-[400px] sm:w-auto">
        <div className="flex justify-center items-center mt-10">
          <h2 className="font-bold text-3xl">Mata Pelajaran {mapel}</h2>
        </div>
        <div className="bg-white mx-5 mt-5 rounded-lg drop-shadow-lg py-5">
          <div className="flex justify-center items-center">
            <label className="mr-3">Input Semester:</label>
            <select
              value={semester}
              onChange={handleSemesterChange}
              className="border rounded-lg p-2 border-slate-500"
            >
              <option value="1">Semester 1</option>
              <option value="2">Semester 2</option>
            </select>
          </div>
          <div className="grid lg:grid-cols-3 lg:gap-5 justify-center items-center">
            {questions
              .filter((question) => question.semester === semester)
              .map((question) => (
                <div key={question._id} className="mt-10">
                  <div className="bg-blue-200 w-80 h-40 rounded-xl flex flex-col items-center drop-shadow-lg">
                    <div className="font-bold text-2xl gap-2 flex my-4">
                      <p>{question.jenis}</p>
                    </div>
                    <div className="text-2xl">
                      <h2>
                        kelas: {question.kelas}
                        {question.rombel}
                      </h2>
                    </div>
                    <div className="pt-4 flex gap-3">
                      <div>
                        <button
                          className="bg-blue-500 px-3 py-2 rounded-xl text-white"
                          onClick={() => handleToggle(question._id)}
                        >
                          {question.status ? "Tutup" : "Buka"}
                        </button>
                      </div>
                      <div>
                        <button className="bg-green-400 hover:bg-green-600 px-3 py-2 rounded-xl text-white">
                          <Link
                            to={`/guru/elearning-guru/${mapel}/edit/${question._id}`}
                            className="text-white"
                          >
                            Edit
                          </Link>
                        </button>
                      </div>
                      <div>
                        <button
                          className="bg-red-400 hover:bg-red-600 px-3 py-2 rounded-xl text-white"
                          onClick={() => handleDelete(question._id)}
                        >
                          Hapus
                        </button>
                      </div>
                      <div>
                        <button className="bg-green-400 hover:bg-green-600 px-3 py-2 rounded-xl text-white">
                          <Link
                            to={`/guru/elearning-guru/${mapel}/nilai/${question._id}`}
                            className="text-white"
                          >
                            Nilai
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <Link to="/guru/addquestion">
            <button className="bg-red-400 px-5 py-3 rounded-xl">
              Tambah soal
            </button>
          </Link>
        </div>

        <div>
          <Persen mapel={mapel} onEdit={() => setIsEditing(true)} />
          {isEditing && (
            <TabelPersen mapel={mapel} onSubmit={() => setIsEditing(false)} />
          )}

          <JumlahNilai mapel={mapel} />
          <KURKUMlist mapel={mapel} />
          {/* <TujuanPembelajran mapel={mapel} /> */}
          <NilaiRaporList mapel={mapel} />
        </div>
        <div className="flex justify-center mt-20">
          <Link to={"/guru"}>
            <Back />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ElearningGuru;
