import axios from "axios";
import React, { useEffect, useState } from "react";
import Ekskul from "./Ekskul";

const ProfileSekolah = () => {
  const [schoolData, setSchoolData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataKepsek, setDataKepsek] = useState([]);

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchoolData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching school data:", error);
        setError("Failed to load school data.");
        setLoading(false);
      }
    };

    fetchSchoolData();
  }, []);

  useEffect(() => {
    const fetchKepsek = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/user-images-and-roles`
        );
        // Filter hanya data dengan jabatan "Kepala Sekolah"
        const kepalaSekolah = response.data.filter(
          (user) => user.jabatan === "Kepala Sekolah"
        );
        setDataKepsek(kepalaSekolah); // Simpan data yang difilter ke state
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data.");
        setLoading(false);
      }
    };
    fetchKepsek();
  }, []);

  if (loading)
    return (
      <div className="text-center mt-10 text-gray-500">
        Loading school data...
      </div>
    );
  if (error)
    return <div className="text-center mt-10 text-red-500">{error}</div>;

  return (
    <div className="bg-gray-50 min-h-screen py-10 px-5">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
        Profil Sekolah
      </h1>
      <section className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex items-center space-x-8 flex-col md:flex-row">
            <div>

              {dataKepsek.length > 0 ? (
                dataKepsek.map((kepsek) => (
                  <div key={kepsek._id}>
                    <div className="w-[300px] h-[400px]">
                      <img
                        src={kepsek.image || "/professor.jpg"} //
                        alt={kepsek.name || "Kepala Sekolah"}
                        className="w-full h-full object-cover rounded-lg shadow-lg"
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>Tidak ada data Kepala Sekolah.</p>
              )}
            </div>

            <div className="flex-1 text-gray-800 text-justify">
              <h2 className="text-3xl font-bold mb-4">
                Salam dari Kepala Sekolah
              </h2>
              {schoolData.map((user) => (
                <div key={user._id}>
                  {user.salam_kepsek.map((data) => (
                    <div
                      key={data._id}
                      className="flex justify-left mx-2 my-5 lg:mx-10"
                    >
                      <p className="text-base mb-2">
                        {data.text}
                      </p>

                    </div>
                  ))}
                </div>
              ))}
              <div>
                {dataKepsek.length > 0 ? (
                  dataKepsek.map((kepsek) => (
                    <p key={kepsek._id} className="text-base font-bold sans-serrif">
                      {kepsek.name} {/* Menampilkan nama Kepala Sekolah */}
                    </p>
                  ))
                ) : (
                  <p>Tidak ada data Kepala Sekolah.</p>
                )}
              </div>
              <p className="text-base">Kepala Sekolah</p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex items-center space-x-8 flex-col lg:flex-row">
            <div className="flex-shrink-0 lg:w-1/3 w-2/3 ">
              <img
                src="/profil-sekolah.png"
                alt="Profil"
                className="w-full h-full h-auto rounded-lg shadow-lg"
              />
            </div>

            <div className="flex-1 text-gray-800">
              <h2 className="text-3xl font-bold mb-4">Sejarah Singkat</h2>
              {schoolData.map((user) => (
                <div key={user._id}>
                  {user.sejarah_singkat.map((data) => (
                    <div
                      key={data._id}
                      className="flex justify-center mx-10 my-5 lg:mx-32"
                    >
                      <p className="text-md text-justify font-serif">
                        {data.text}
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-2">
        <div className="max-w-7xl mx-auto px-6">
          <div className="flex flex-col items-center space-x-8">
          <h2 className="text-3xl font-bold mb-6">Visi Sekolah</h2>
            {schoolData.map((user) => (
              <div key={user._id}>
                {user.visi.map((data) => (
                  <div
                    key={data._id}
                    className="flex justify-center mx-10 my-5 lg:mx-32"
                  >
                    <p className="text-md text-center font-serif">
                      " {data.isiVisi} "
                    </p>
                  </div>
                ))}
              </div>
            ))}
            <h2 className="text-3xl font-bold mb-6">Misi Sekolah</h2>
            {schoolData.map((user) => (
              <div key={user._id}>
                {user.misi.map((data, index) => (
                  <div
                    key={data._id}
                    className="flex justify-start flex-row mx-10 my-4 lg:mx-36"
                  >
                    <p className="pr-2">{index + 1}.</p>

                    <p className="text-md text-left font-serif text-gray-800">
                      {data.isiMisi}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>

      <Ekskul />
      <div className="space-y-10">
        {schoolData.length > 0 ? (
          schoolData.map((school) => (
            <div
              key={school._id}
              className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-blue-50 to-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-all"
            >
              {/* Gambar Sekolah */}
              <div className="lg:w-1/3 w-full">
                <img
                  src={school.gambar || "profil-sekolah.png"}
                  alt={`Foto ${school.namasekolah}`}
                  className="w-full h-64 object-cover hover:scale-105 transition-transform"
                />
              </div>
              {/* Detail Sekolah */}
              <div className="lg:w-2/3 w-full p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-4 hover:text-blue-500 transition-colors">
                  {school.namasekolah}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 text-gray-600">
                  <p>
                    <strong>NPSN:</strong> {school.NPSN}
                  </p>
                  <p>
                    <strong>Alamat:</strong> {school.alamat}
                  </p>
                  <p>
                    <strong>Kecamatan:</strong> {school.Kec}, {school.kab}
                  </p>
                  <p>
                    <strong>Provinsi:</strong> {school.prov}
                  </p>
                  <p>
                    <strong>Akreditasi:</strong> {school.Akreditasi}
                  </p>
                  <p>
                    <strong>Status:</strong> {school.statusSekolah}
                  </p>
                  <p>
                    <strong>Email:</strong>{" "}
                    <a
                      href={`mailto:${school.email}`}
                      className="text-blue-500 hover:underline"
                    >
                      {school.email}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">
            Data sekolah tidak ditemukan.
          </p>
        )}
      </div>
    </div>
  );
};

export default ProfileSekolah;
