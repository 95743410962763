import React, { useRef, useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";

// eslint-disable-next-line react/prop-types
const Back = ({
  kata = "kembali",
  color = "bg-gradient-to-tr from-cyan-600 to-cyan-400",
  ...props
}) => {
  const [hovered, setHover] = useState(false);
  const ref = useRef(null);

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex items-center  rounded-full text-white ${color}`}
      {...props}
    >
      <IoArrowBackCircle className="text-white text-4xl drop-shadow-lg" />
      <div
        style={{ width: hovered ? ref.current?.offsetWidth || 0 : 0 }}
        className="overflow-x-hidden transition-all duration-300 ease-out"
      >
        <span
          ref={ref}
          className={`pl-2 pr-5 font-bold text-lg ${
            hovered ? "" : "invisible"
          }`}
        >
          {kata}
        </span>
      </div>
    </button>
  );
};

export default Back;
