import React from "react";
import KURKUMlist from "./KURKUMlist";

const CPadmin = () => {
  return (
    <div>
      <div>
        <div>
          <KURKUMlist />
        </div>
      </div>
    </div>
  );
};

export default CPadmin;
