import React from "react";

const KertasA4 = ({ children }) => {
  return (
    <div
      className="bg-white shadow-md mx-auto my-8 p-16"
      style={{ width: "210mm", height: "297mm" }}
    >
      {children}
    </div>
  );
};

export default KertasA4;
