import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaMapMarkerAlt,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  const [schoolData, setSchoolData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`
        );
        setSchoolData(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-[#EE4D12] text-black py-5 ">
      {/* Baris 1: Nama Sekolah */}
      {schoolData.length > 0 && (
        <div className="flex justify-center pb-7 w-4/5 mx-auto ">
          {schoolData.map((school) => (
            <h1 key={school._id} className=" lg:text-lg text-xs font-bold ">
              {school.namasekolah} | © Copyright 2024
            </h1>
          ))}
        </div>
      )}

      {/* Baris 2: Ikon kontak */}
      <div className="flex justify-center py-5 text-sm gap-10 px-5">
        {schoolData.map((school) => (
          <div key={school._id} className="grid md:flex justify-center gap-5 ">
            <div className="flex flex-col items-center">
              <FaMapMarkerAlt size={20} />
              <p className="mt-2">{school.alamat}</p>
            </div>
            <div className="flex flex-col items-center">
              <FaEnvelope size={20} />
              <p className="mt-2">{school.email}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Garis Pemisah */}
      <hr className="border-t-2 border-black mx-96" />

      {/* Baris 3: Media Sosial */}
      <div className="flex justify-center space-x-5  py-5">
        <a
          href="https://www.instagram.com/sdn2dasanlekong/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={25} className="hover:text-gray-200 " />
        </a>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={25} className="hover:text-gray-200" />
        </a>
        <a
          href="https://www.youtube.com/@sdn2dasanlekong433"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube size={25} className="hover:text-gray-200" />
        </a>
      </div>

      
    </div>
  );
};

export default Footer;
