import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Back from "../button/back";

const fieldLabels = {
  BBKTYMEBM:
    "Beriman, Bertakwa Kepada Tuhan Yang Maha Esa, dan Berakhlak Mulia",
  BerkebhinekaanGlobal: "Berkebhinekaan Global",
  BergotongRoyong: "Bergotong Royong",
  Mandiri: "Mandiri",
  BernalarKritis: "Bernalar Kritis",
  Kreatif: "Kreatif",
};

const subFieldLabels = {
  akhlakberagama: "Akhlak beragama",
  AkhlakPribadi: "Akhlak Pribadi",
  akhlakkepadamanusia: "Akhlak kepada manusia",
  akhlakkepadaalam: "Akhlak kepada alam",
  akhlakbernegara: "Akhlak bernegara",
  mengenaldanmenghargaibudaya: "Mengenal dan menghargai budaya",
  komunikasidaninteraksiantarbudaya: "Komunikasi dan interaksi antarbudaya",
  refleksidanbertanggungjawabterhadappengalamankebinekaan:
    "Refleksi dan bertanggungjawab terhadap pengalaman kebinekaan",
  BerkeadilanSosial: "Berkeadilan Sosial",
  kolaborasi: "Kolaborasi",
  kepedulian: "Kepedulian",
  Berbagi: "Berbagi",
  Pemahamandiridansituasiyangdihadapi:
    "Pemahaman diri dan situasi yang dihadapi",
  RegulasiDiri: "Regulasi Diri",
  memperolehdanmemprosesinformasidangagasan:
    "Memperoleh dan memproses informasi dan gagasan",
  menganalisisdanmengevaluasipenalarandanprosedurnya:
    "Menganalisis dan mengevaluasi penalaran dan prosedurnya",
  Elemenrefleksipemikirandanprosesberpikir:
    "Refleksi pemikiran dan proses berpikir",
  menghasilkangagasanyangorisina: "Menghasilkan gagasan yang orisinal",
  memilikikeluwesanberpikirdalammencarialternatifsolusipermasalahan:
    "Memiliki keluwesan berpikir dalam mencari alternatif solusi permasalahan",
};

const EditP5 = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/p5/all`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filter = response.data.find((item) => item._id === id);
        setFormData(filter);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNestedArrayChange = (e, index, field, subField) => {
    const { value } = e.target;
    setFormData((prevData) => {
      const newArray = [...prevData.data[0][field][0][subField]];
      newArray[index] = value;
      return {
        ...prevData,
        data: [
          {
            ...prevData.data[0],
            [field]: [
              {
                ...prevData.data[0][field][0],
                [subField]: newArray,
              },
            ],
          },
        ],
      };
    });
  };

  const addNestedArrayField = (field, subField) => {
    setFormData((prevData) => ({
      ...prevData,
      data: [
        {
          ...prevData.data[0],
          [field]: [
            {
              ...prevData.data[0][field][0],
              [subField]: [...prevData.data[0][field][0][subField], ""],
            },
          ],
        },
      ],
    }));
  };

  const removeNestedArrayField = (index, field, subField) => {
    setFormData((prevData) => {
      const newArray = [...prevData.data[0][field][0][subField]];
      newArray.splice(index, 1);
      return {
        ...prevData,
        data: [
          {
            ...prevData.data[0],
            [field]: [
              {
                ...prevData.data[0][field][0],
                [subField]: newArray,
              },
            ],
          },
        ],
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/p5/${id}`;
      await axios.put(url, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Data berhasil disimpan");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Data telah tersedia");
      } else {
        toast.error("Data tidak berhasil disimpan");
      }
    }
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Edit data P5</h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Kelas:
          </label>
          <select
            name="kelas"
            value={formData.kelas}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value=""> pilih kelas</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Fase:
          </label>
          <select
            name="fase"
            value={formData.fase}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded-md"
            required
          >
            <option value="">pilih</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>
        {Object.keys(fieldLabels).map((field) => (
          <div key={field}>
            <label className="block mb-2 text-lg font-bold text-gray-700">
              {fieldLabels[field]}:
            </label>
            {formData.data[0][field] &&
              Object.keys(formData.data[0][field][0]).map(
                (subField) =>
                  subField !== "_id" && ( // Hide subField '_id'
                    <div key={subField} className="ml-10">
                      <label className="block mb-2 text-sm font-medium text-gray-700">
                        {subFieldLabels[subField]}:
                      </label>
                      {Array.isArray(formData.data[0][field][0][subField]) &&
                        formData.data[0][field][0][subField].map(
                          (value, index) => (
                            <div key={index} className="flex items-center mb-2">
                              <input
                                type="text"
                                value={value}
                                onChange={(e) =>
                                  handleNestedArrayChange(
                                    e,
                                    index,
                                    field,
                                    subField
                                  )
                                }
                                className="w-full border border-gray-300 p-2 rounded-md"
                              />
                              <button
                                type="button"
                                onClick={() =>
                                  removeNestedArrayField(index, field, subField)
                                }
                                className="ml-2 text-red-500 hover:text-red-700"
                              >
                                Hapus
                              </button>
                            </div>
                          )
                        )}
                      <button
                        type="button"
                        onClick={() => addNestedArrayField(field, subField)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Tambah {subFieldLabels[subField]}
                      </button>
                    </div>
                  )
              )}
          </div>
        ))}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          >
            Simpan
          </button>
        </div>
      </form>
      <ToastContainer />
      <div className="flex justify-center mt-4">
        <Link to={`/listp5/add`} className="text-blue-500 hover:text-blue-700">
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EditP5;
