import React from "react";
import RaporUserList from "./RaporComponet/RaporUserList";

const Rapor = () => {
  return (
    <div>
      <div>
        <RaporUserList />
      </div>
    </div>
  );
};

export default Rapor;
