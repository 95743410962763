// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"; // Corrected import

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDDxu7KHdBOP1IDYqklhRY9lG64GgyP0-w",
  authDomain: "e-raporsd.firebaseapp.com",
  projectId: "e-raporsd",
  storageBucket: "e-raporsd.appspot.com",
  messagingSenderId: "505457417875",
  appId: "1:505457417875:web:1cdafabbd254e10b607766",
  measurementId: "G-PDWTMB8DSD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app); // Corrected initialization
const db = getFirestore(app); // Properly initialize Firestore

// Export the initialized services
export { storage, db };
