import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Back from "../components/button/back";

const ListEkskul = () => {
  const [ekskulData, setEkskulData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/ekskul`
        );
        setEkskulData(response.data);
      } catch (error) {
        console.error("Error fetching ekskul data:", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}api/ekskul/${id}`);
      setEkskulData(ekskulData.filter((ekskul) => ekskul._id !== id));
    } catch (error) {
      console.error("Error deleting ekskul:", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit-ekskul/${id}`);
  };

  const handleAdd = () => {
    navigate("/admin/ektrakulikuler/add-ekskul");
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };

  return (
    <div className="container mx-auto px-5 mt-10">
      <h1 className="text-2xl font-bold mb-5">Ekskul List</h1>
      <button
        onClick={handleAdd}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-5"
      >
        Tambah Ekskul
      </button>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                No
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                Judul
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                Deskripsi
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                Gambar
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                Edit
              </th>
              <th className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-xs uppercase font-semibold text-gray-700">
                Hapus
              </th>
            </tr>
          </thead>
          <tbody>
            {ekskulData.map((ekskul, index) => (
              <tr key={ekskul._id}>
                <td className="py-2 px-4 border-b border-gray-200">
                  {index + 1}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {ekskul.Judul}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {truncateText(ekskul.Deskripsi, 3)}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  {ekskul.Gambar ? (
                    <img
                      src={`${ekskul.Gambar}`}
                      alt={ekskul.Judul}
                      className="w-16 h-16 object-cover"
                    />
                  ) : (
                    "No Image"
                  )}
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <button
                    onClick={() => handleEdit(ekskul._id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded mr-2"
                  >
                    Edit
                  </button>
                </td>
                <td className="py-2 px-4 border-b border-gray-200">
                  <button
                    onClick={() => handleDelete(ekskul._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                  >
                    Hapus
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center my-10">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default ListEkskul;
