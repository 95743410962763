// components/SiswaDashboard.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const subjects = [
  { name: "Matematika", path: "MTK" },
  { name: "IPA", path: "IPA" },
  { name: "IPS", path: "IPS" },
  { name: "IPAS", path: "IPAS" },
  { name: "Pendidikan Agama", path: "PAI" },
  { name: "Bahasa Indonesia", path: "BIndo" },
  { name: "Bahasa Inggris", path: "BIng" },
  { name: "Pendidikan Jasmani", path: "PJOK" },
  { name: "Senibudaya", path: "SENIBUDAYA" },
  { name: "MULOK", path: "MULOK" },
  { name: "PKN", path: "PKN" },
];

const SiswaDashboard = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/auth/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUser(res.data);
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  const studentClass = parseInt(user.kelas, 10);
  const filteredSubjects = subjects.filter((subject) => {
    if (studentClass === 1 || studentClass === 2) {
      return (
        subject.path !== "IPA" &&
        subject.path !== "IPS" &&
        subject.path !== "IPAS"
      );
    }
    return true;
  });

  return (
    <div className="grid justify-center min-h-screen bg-blue-100">
      <div>
        <div className="flex justify-center items-center my-10">
          <h2 className="font-bold text-3xl">Dashboard Siswa</h2>
        </div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-2 gap-x-5 gap-y-10 md:grid-cols-3 md:gap-5">
            {filteredSubjects.map((subject) => (
              <div
                key={subject.path}
                className="bg-white drop-shadow-lg h-40 w-40 flex justify-center items-center font-bold rounded-lg hover:bg-slate-300 transform transition-transform duration-300 hover:scale-105"
              >
                <Link
                  to={`/siswa/elearning-siswa/${subject.path}`}
                  className="flex justify-center items-center text-center h-full w-full"
                >
                  <h1 className="text-2xl">{subject.name}</h1>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiswaDashboard;
