import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Back from "../components/button/back";

const KURKUMedit = () => {
  const Navigate = useNavigate();
  const { id } = useParams(); // Mengambil ID dari URL
  const [editedTP, setEditedTP] = useState({
    mapel: "",
    kelas: "",
    semester: "",
    TP: [],
  });
  const [notification, setNotification] = useState(""); // State untuk notifikasi

  useEffect(() => {
    const fetchTPData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/tp/${id}`
        );
        setEditedTP(response.data);
      } catch (error) {
        console.error("Error fetching TP data:", error);
      }
    };

    fetchTPData();
  }, [id]);

  const handleInputChange = (index, event) => {
    const updatedTP = [...editedTP.TP];
    updatedTP[index].isiTP = event.target.value;
    setEditedTP({
      ...editedTP,
      TP: updatedTP,
    });
  };

  const handleDeleteTP = (index) => {
    const updatedTP = editedTP.TP.filter((_, i) => i !== index);
    setEditedTP({
      ...editedTP,
      TP: updatedTP,
    });
  };

  const handleAddTP = () => {
    const newTP = { isiTP: "" };
    setEditedTP({
      ...editedTP,
      TP: [...editedTP.TP, newTP],
    });
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/tp/${id}`,
        editedTP,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Navigate(`/guru/elearning-guru/${editedTP.mapel}`);
      setNotification("Changes saved successfully!");
      setTimeout(() => setNotification(""), 3000);
      console.log("Changes saved:", response.data);
    } catch (error) {
      setNotification("Error saving changes!");
      setTimeout(() => setNotification(""), 3000);
      console.error("Error saving changes:", error);
    }
  };

  return (
    <div className="min-h-screen bg-blue-100 py-10">
      <div className="bg-white mx-5 rounded-lg drop-shadow-lg">
        <div>
          <div className="flex justify-center py-5">
            <h1 className="font-bold text-2xl">Edit TP</h1>
          </div>
          {notification && <p>{notification}</p>}
        </div>
        <div className=" border border-slate-500 rounded-lg mx-5 px-5">
          <div className="my-5">
            <div className="grid gap-y-2">
              <div className="flex">
                <label htmlFor="mapel">Mapel:</label>
                <input
                  type="text"
                  id="mapel"
                  value={editedTP.mapel}
                  onChange={(e) =>
                    setEditedTP({ ...editedTP, mapel: e.target.value })
                  }
                  className="border border-slate-500 rounded-lg px-2 ml-3"
                />
              </div>
              <div className="flex">
                <label htmlFor="kelas">Kelas:</label>
                <input
                  type="text"
                  id="kelas"
                  value={editedTP.kelas}
                  onChange={(e) =>
                    setEditedTP({ ...editedTP, kelas: e.target.value })
                  }
                  className="border border-slate-500 rounded-lg px-2 ml-3"
                />
              </div>
              <div className="flex">
                <label htmlFor="semester">Semester:</label>
                <input
                  type="text"
                  id="semester"
                  value={editedTP.semester}
                  onChange={(e) =>
                    setEditedTP({ ...editedTP, semester: e.target.value })
                  }
                  className="border border-slate-500 rounded-lg px-2 ml-3"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex justify-center py-5">
            <h2 className="text-2xl font-bold">
              Daftar Tujuan Pembelajaran (TP)
            </h2>
          </div>
          <div className="border border-slate-500 rounded-lg p-5 mx-5 ">
            {editedTP.TP.length === 0 ? (
              <p>Tidak ada data yang ditemukan.</p>
            ) : (
              <ul>
                {editedTP.TP.map((tp, index) => (
                  <li
                    key={index}
                    className="flex justify-center items-center gap-1"
                  >
                    <div>CP {index + 1}:</div>
                    <div>
                      <textarea
                        type="text"
                        value={tp.isiTP}
                        onChange={(e) => handleInputChange(index, e)}
                        className="border border-slate-500 rounded-lg w-[200px] h-[100px] md:w-[500px] p-2"
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => handleDeleteTP(index)}
                        className="bg-red-400 rounded-lg px-2 py-1"
                      >
                        Hapus
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            <div className="flex justify-center my-5">
              <button
                onClick={handleAddTP}
                className="bg-green-400 p-2 rounded-lg hover:bg-green-600"
              >
                Tambah CP
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-5 pb-10">
          <button
            onClick={handleSaveChanges}
            className="bg-blue-400 py-2 px-2 rounded-lg hover:bg-blue-600 text-white"
          >
            Simpan Perubahan
          </button>
        </div>
      </div>

      <div className="flex justify-center my-5">
        <Link to={`/guru/elearning-guru/${editedTP.mapel}`}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default KURKUMedit;
