import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Back from "../button/back";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [datasekolah, setDatasekolah] = useState();
  const [namaKepsek, setNamaKepsek] = useState(null);
  const [wakel, setWakel] = useState();
  const [kelasFilter, setKelasFilter] = useState("");
  const [rombelFilter, setRombelFilter] = useState("");
  const [tahun, setTahun] = useState("");
  const [semester, setSemester] = useState("");
  const [inputData, setInputData] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/auth/users`;

        const params = {};
        if (kelasFilter) params.kelas = kelasFilter;
        if (rombelFilter) params.rombel = rombelFilter;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        });

        const filteredUsers = response.data.filter((user) => {
          return (
            user.role === "siswa" &&
            (!kelasFilter || user.kelas === kelasFilter) &&
            (!rombelFilter || user.rombel === rombelFilter)
          );
        });
        const filteredGuru = response.data.filter((user) => {
          return (
            user.jabatan === "Guru Kelas" &&
            (!kelasFilter || user.kelas === kelasFilter) &&
            (!rombelFilter || user.rombel === rombelFilter)
          );
        });
        setWakel(filteredGuru[0]);
        const kepalaSekolah = response.data.filter(
          (user) => user.jabatan === "Kepala Sekolah"
        );

        setNamaKepsek(kepalaSekolah); // Simpan data Kepala Sekolah ke state

        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };


    const fetchdataSekolah = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`,
        );
        setDatasekolah(response.data);
      } catch (error) {
        console.error("Error fetching Kepala Sekolah data:", error);
      }
    };

    fetchdataSekolah();
    fetchUsers();
  }, [kelasFilter, rombelFilter]);

  const handleKelasChange = (e) => {
    setKelasFilter(e.target.value);
  };

  const handleRombelChange = (e) => {
    setRombelFilter(e.target.value);
  };

  const handleTahunChange = (e) => {
    setTahun(e.target.value);
  };

  const handleSemesterChange = (e) => {
    setSemester(e.target.value);
  };

  const handleInputChange = (userId, index, fieldName, value) => {
    setInputData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        ekskul: (prevData[userId]?.ekskul || []).map((item, idx) =>
          idx === index ? { ...item, [fieldName]: value } : item
        ),
      },
    }));
  };

  const handleAddEkskul = (userId) => {
    setInputData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId],
        ekskul: [
          ...(prevData[userId]?.ekskul || []),
          { nama: "", deskripsi: "" },
        ],
      },
    }));
  };

  const handleRemoveEkskul = (userId, index) => {
    setInputData((prevData) => ({
      ...prevData,
      [userId]: {
        ...prevData[userId], // Corrected here
        ekskul: prevData[userId].ekskul.filter((_, idx) => idx !== index),
      },
    }));
  };

  const handleSubmitAll = async () => {
    if (!kelasFilter || !rombelFilter || !tahun || !semester) {
      toast.error(
        "Please fill in all the filters and input fields before submitting."
      );
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/dataRapor/all`;

      const dataKeterangan = users.map((user) => ({
        studentId: user._id,
        name: user.name,
        nisn: user.NISN,
        nipd: user.NIPD,
        bulan: inputData[user._id]?.bulan || user.bulan,
        tanggal: parseInt(
          inputData[user._id]?.tanggal || user.tanggal || 0,
          10
        ),
        sakit: parseInt(inputData[user._id]?.sakit || user.sakit || 0, 10),
        izin: parseInt(inputData[user._id]?.izin || user.izin || 0, 10),
        tanpaKeterangan: parseInt(
          inputData[user._id]?.tanpaKeterangan || user.tanpaKeterangan || 0,
          10
        ),
        ekskul: (inputData[user._id]?.ekskul || user.ekskul || []).map(
          (ekskul) => ({
            ekskulName: ekskul.nama,
            ekskulKet: ekskul.deskripsi,
          })
        ),
        CatatanaWakel: inputData[user._id]?.CatatanaWakel || user.CatatanaWakel,
      }));

      const dataToSend = {
        kelas: kelasFilter,
        rombel: rombelFilter,
        tahun,
        semester,
        nama_sekolah: datasekolah[0].namasekolah,
        alamat_sekolah: datasekolah[0].alamat,
        kota: datasekolah[0].ttDsekolah,
        nama_wakel: wakel.name,
        nip_wakel: wakel.NIP,
        namakepsek: namaKepsek[0].username,
        nip_kepsek: namaKepsek[0].NIP,
        dataKeterangan,
      };

      console.log("Submitting the following data:", dataToSend);

      await axios.post(url, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setInputData({});
      toast.success("Data saved successfully!");
    } catch (error) {
      if (error.response) {
        if (error.response.data.message.includes("already exists")) {
          toast.error(
            "Data with the same year, semester, class, and rombel already exists."
          );
        } else {
          toast.error(
            `Server responded with an error: ${error.response.data.message}`
          );
        }
      } else if (error.request) {
        toast.error("No response received from the server.");
      } else {
        toast.error(`Error setting up the request: ${error.message}`);
      }
      console.error("Error submitting all data:", error);
    }
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <div>
        <div>
          <div>
            <h1 className="text-2xl font-bold mb-4">List Siswa/siswi</h1>
          </div>

          {/* Filter Form */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="mb-2 flex flex-col">
              <label htmlFor="tahun" className="block mb-2 font-medium">
                Tahun:
              </label>
              <input
                type="text"
                id="tahun"
                name="tahun"
                value={tahun}
                onChange={handleTahunChange}
                className="block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>

            <div className="mb-2 flex flex-col">
              <label htmlFor="semester" className="block mb-2 font-medium">
                Semester:
              </label>
              <select
                id="semester"
                value={semester}
                onChange={handleSemesterChange}
                className="block w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">All</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </div>

            <div className="mb-2 flex flex-col">
              <label htmlFor="kelas" className="block mb-2 font-medium">
                Kelas:
              </label>
              <select
                id="kelas"
                value={kelasFilter}
                onChange={handleKelasChange}
                className="block w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">All</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </div>

            <div className="mb-2 flex flex-col">
              <label htmlFor="rombel" className="block mb-2 font-medium">
                Rombel:
              </label>
              <select
                id="rombel"
                value={rombelFilter}
                onChange={handleRombelChange}
                className="block w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">All</option>
                <option value="A">A</option>
                <option value="B">B</option>
              </select>
            </div>
          </div>
        </div>

      </div>

      <div className="overflow-x-auto overflow-y-auto h-screen">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-500">
            <tr>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Name
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Kelas
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Rombel
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Bulan bagi rapor
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Tanggal bagi rapor
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Sakit
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Izin
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Tanpa Keterangan
              </th>
              <th
                rowSpan="2"
                colSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider "
              >
                Ekskul
              </th>
              <th
                rowSpan="2"
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                Catatan Walikelas
              </th>
            </tr>
            {/* <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Nama Ekskul
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Deskripsi Ekskul
              </th>
            </tr> */}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user._id}>
                <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{kelasFilter}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rombelFilter}</td>

                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    type="text"
                    value={inputData[user._id]?.bulan || user.bulan || ""}
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          bulan: e.target.value,
                        },
                      }))
                    }
                    className="block w-full p-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Bulan</option>
                    <option value="Januari">Januari</option>
                    <option value="Februari">Februari</option>
                    <option value="Maret">Maret</option>
                    <option value="April">April</option>
                    <option value="Mei">Mei</option>
                    <option value="Juni">Juni</option>
                    <option value="Juli">Juli</option>
                    <option value="Agustus">Agustus</option>
                    <option value="September">September</option>
                    <option value="Oktober">Oktober</option>
                    <option value="November">Novermber</option>
                    <option value="Desember">Desember</option>
                  </select>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="number"
                    value={inputData[user._id]?.tanggal || user.tanggal || ""}
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          tanggal: e.target.value,
                        },
                      }))
                    }
                    className="block w-full p-2 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="number"
                    value={inputData[user._id]?.sakit || user.sakit || ""}
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          sakit: e.target.value,
                        },
                      }))
                    }
                    className="block w-full p-2 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="number"
                    value={inputData[user._id]?.izin || user.izin || ""}
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          izin: e.target.value,
                        },
                      }))
                    }
                    className="block w-full p-2 border border-gray-300 rounded-md"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="number"
                    value={
                      inputData[user._id]?.tanpaKeterangan ||
                      user.tanpaKeterangan ||
                      ""
                    }
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          tanpaKeterangan: e.target.value,
                        },
                      }))
                    }
                    className="block w-full p-2 border border-gray-300 rounded-md"
                  />
                </td>
                <td colSpan="2" className="px-6 py-4 whitespace-nowrap">
                  {inputData[user._id]?.ekskul?.map((item, index) => (
                    <div key={index} className="grid gap-2 mb-2">
                      <input
                        type="text"
                        placeholder="Nama Ekskul"
                        value={item.nama || ""}
                        onChange={(e) =>
                          handleInputChange(
                            user._id,
                            index,
                            "nama",
                            e.target.value
                          )
                        }
                        className="block w-full p-2 border border-gray-300 rounded-md mr-2"
                      />
                      <input
                        type="text"
                        placeholder="Deskripsi Ekskul"
                        value={item.deskripsi || ""}
                        onChange={(e) =>
                          handleInputChange(
                            user._id,
                            index,
                            "deskripsi",
                            e.target.value
                          )
                        }
                        className="block w-full p-2 border border-gray-300 rounded-md mr-2"
                      />
                      <button
                        onClick={() => handleRemoveEkskul(user._id, index)}
                        className="text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => handleAddEkskul(user._id)}
                    className="mt-2 text-blue-600 hover:text-blue-800"
                  >
                    Add Ekskul
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <input
                    type="textarea"
                    value={
                      inputData[user._id]?.CatatanaWakel ||
                      user.CatatanaWakel ||
                      ""
                    }
                    onChange={(e) =>
                      setInputData((prevData) => ({
                        ...prevData,
                        [user._id]: {
                          ...prevData[user._id],
                          CatatanaWakel: e.target.value,
                        },
                      }))
                    }
                    className="block w-auto h-auto p-2 border border-gray-300 rounded-md"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button
          onClick={handleSubmitAll}
          className="px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700"
        >
          Submit All
        </button>
      </div>
      <div className="flex justify-center my-10">
        <Link to={"/guru/rapor"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default UserList;
