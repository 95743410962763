import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ProfileGuru = () => {
  const { id } = useParams(); // Teacher ID from URL params
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSemester, setSelectedSemester] = useState("1"); // Default semester 1
  const [selectedYear, setSelectedYear] = useState(""); // Default year filter

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          alert("Anda harus login untuk mengakses halaman ini.");
          navigate("/login");
          return;
        }

        const decoded = jwtDecode(token);
        if (!decoded) {
          alert("Token tidak valid. Silakan login kembali.");
          navigate("/login");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/nilaifinal/all`,
          config
        );

        // Filter data berdasarkan createdBy (Guru ID) dan rombel/kelas
        const filtered = response.data.filter((item) => {
          return item.createdBy === id;
        });

        setFilteredData(filtered);
        setLoading(false);
      } catch (err) {
        setError(err.response?.data?.message || "Terjadi kesalahan");
        setLoading(false);
      }
    };

    fetchData();
  }, [id, navigate]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // Filter data berdasarkan semester yang dipilih dan tahun ajaran
  const filteredBySemesterAndYear = filteredData.filter(
    (item) =>
      item.semester === selectedSemester &&
      (selectedYear ? item.tahun === selectedYear : true)
  );

  // Fungsi untuk mengelompokkan data berdasarkan mapel, tahun dan semester
  const groupedData = filteredBySemesterAndYear.reduce((acc, item) => {
    const { mapel, tahun, semester, nilai } = item;

    if (!acc[mapel]) {
      acc[mapel] = {};
    }

    if (!acc[mapel][tahun]) {
      acc[mapel][tahun] = {};
    }

    acc[mapel][tahun][semester] = nilai.length > 0 ? nilai[0].finalScore : "-";

    return acc;
  }, {});

  // Ambil daftar tahun ajaran, semester, dan mapel
  const allYears = Array.from(
    new Set(filteredBySemesterAndYear.map((item) => item.tahun))
  ).sort();
  const semesters = ["1", "2"];
  const mapels = Object.keys(groupedData);

  return (
    <div className="flex flex-col items-center justify-center">
      {filteredBySemesterAndYear.length === 0 ? (
        <div className="text-center mt-4 text-xl">Data belum ada</div>
      ) : (
        <div className="w-full">
          {/* Filter Tahun Ajaran */}
          <div className="flex mb-4 justify-center">
            <label htmlFor="year" className="p-2">
              Pilih Tahun Ajaran:
            </label>
            <select
              id="year"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="p-2 border rounded"
            >
              <option value="">Semua Tahun</option>
              {allYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="flex mb-4  justify-center">
            <label htmlFor="semester" className="p-2">
              Pilih Semester:
            </label>
            <select
              id="semester"
              value={selectedSemester}
              onChange={(e) => setSelectedSemester(e.target.value)}
              className="p-2 border rounded"
            >
              {semesters.map((sem) => (
                <option key={sem} value={sem}>
                  Semester {sem}
                </option>
              ))}
            </select>
          </div>
          <table className="table-auto mx-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-6 py-3 border border-gray-300" rowSpan="2">
                  Siswa
                </th>
                {allYears.map((year) => (
                  <th
                    key={year}
                    className="px-6 py-3 border border-gray-300"
                    colSpan={mapels.length}
                  >
                    {year}
                  </th>
                ))}
              </tr>
              <tr className="bg-gray-100">
                {allYears.map((year) =>
                  mapels.map((mapel) => (
                    <th
                      key={`${year}-${mapel}`}
                      className="px-6 py-3 border border-gray-300"
                    >
                      {mapel}
                    </th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {filteredBySemesterAndYear.map((item) => (
                <tr key={item._id} className="hover:bg-gray-50">
                  <td className="px-6 py-3 border border-gray-300">
                    {item.nilai[0]?.name}
                  </td>
                  {allYears.map((year) =>
                    mapels.map((mapel) => (
                      <td
                        key={`${year}-${mapel}`}
                        className="px-6 py-3 border border-gray-300"
                      >
                        {groupedData[mapel]?.[year]?.[selectedSemester] || "-"}
                      </td>
                    ))
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProfileGuru;
