import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Back from "../button/back";

const UpdateDataSekolah = () => {
  const [schoolData, setSchoolData] = useState([]);
  const [editingSchool, setEditingSchool] = useState(null);
  const [formData, setFormData] = useState({
    namasekolah: "",
    NPSN: "",
    alamat: "",
    ttDsekolah: "",
    kodePos: "",
    Kec: "",
    kab: "",
    prov: "",
    statusSekolah: "",
    JenjangPendidikan: "",
    naungan: "",
    noSKpendiri: "",
    tglSkPendiri: "",
    noSkOprasional: "",
    Akreditasi: "",
    noSkAkreditasi: "",
    tglSkAkreditasi: "",
    noSertifikatIOS: "",
    fax: "",
    email: "",
    website: "",
    salam_kepsek:[{text:""}],
    sejarah_singkat:[{text:""}],
    visi: [{ isiVisi: "" }],
    misi: [{ isiMisi: "" }],
  });

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/datasekolah/all`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSchoolData(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    fetchSchoolData();
  }, []);

  const handleEditClick = (school) => {
    setEditingSchool(school._id);
    setFormData(school);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVisiMisiChange = (e, index, type) => {
    const { value } = e.target;
    const updatedItems = [...formData[type]];
    updatedItems[index][type === "visi" ? "isiVisi" : "isiMisi"] = value;
    setFormData({ ...formData, [type]: updatedItems });
  };
  const handleSejarahChange = (e, index) => {
    const updatedSejarah = [...formData.sejarah_singkat];
    updatedSejarah[index].text = e.target.value; // Update nilai input berdasarkan index
    setFormData({ ...formData, sejarah_singkat: updatedSejarah });
  };
  const handleSalamChange = (e, index) => {
    const updatedSalam = [...formData.salam_kepsek];
    updatedSalam[index].text = e.target.value; // Update nilai input berdasarkan index
    setFormData({ ...formData, salam_kepsek: updatedSalam });
  };


  const handleAddVisi = () => {
    setFormData({
      ...formData,
      visi: [...formData.visi, { isiVisi: "" }],
    });
  };

  const handleAddMisi = () => {
    setFormData({
      ...formData,
      misi: [...formData.misi, { isiMisi: "" }],
    });
  };
  const handleAddSejarah = () => {
    const updatedSejarah = [...formData.sejarah_singkat, { text: "" }]; // Tambahkan objek baru ke array
    setFormData({ ...formData, sejarah_singkat: updatedSejarah });
  };
  const handleAddSalam = () => {
    const updatedSalam = [...formData.salam_kepsek, { text: "" }]; // Tambahkan objek baru ke array
    setFormData({ ...formData, salam_kepsek: updatedSalam });
  };

  const handleRemoveVisiMisi = (index, type) => {
    const updatedItems = formData[type].filter((_, i) => i !== index);
    setFormData({ ...formData, [type]: updatedItems });
  };
  const handleRemoveSejarah = (index) => {
    const updatedSejarah = formData.sejarah_singkat.filter((_, i) => i !== index); // Filter untuk menghapus item
    setFormData({ ...formData, sejarah_singkat: updatedSejarah });
  };
  const handleRemoveSalam = (index) => {
    const updatedSalam = formData.salam_kepsek.filter((_, i) => i !== index); // Filter untuk menghapus item
    setFormData({ ...formData, salam_kepsek: updatedSalam });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/datasekolah/${editingSchool}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSchoolData((prevData) =>
        prevData.map((school) =>
          school._id === editingSchool ? response.data : school
        )
      );
      setEditingSchool(null);
    } catch (error) {
      console.error("Error updating school data:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingSchool(null);
    setFormData({
      namasekolah: "",
      NPSN: "",
      alamat: "",
      ttDsekolah: "",
      kodePos: "",
      Kec: "",
      kab: "",
      prov: "",
      statusSekolah: "",
      JenjangPendidikan: "",
      naungan: "",
      noSKpendiri: "",
      tglSkPendiri: "",
      noSkOprasional: "",
      Akreditasi: "",
      noSkAkreditasi: "",
      tglSkAkreditasi: "",
      noSertifikatIOS: "",
      fax: "",
      email: "",
      website: "",
      visi: [{ isiVisi: "" }],
      misi: [{ isiMisi: "" }],
    });
  };

  return (
    <div className="max-w-4xl mx-auto py-8 bg-slate-400/50 px-5 my-5 rounded-lg drop-shadow-lg">
      {schoolData.length === 0 && (
        <div>
          <Link to={"/datasekolah"}>
            <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded">
              Add Data Sekolah
            </button>
          </Link>
        </div>
      )}

      <h1 className="text-2xl font-bold mb-4">Update Data Sekolah</h1>
      <div className="space-y-4">
        {schoolData.map((school) => (
          <div key={school._id} className="p-4 bg-white rounded-md shadow-md">
            <h2 className="text-xl font-bold mb-2">{school.namasekolah}</h2>
            <p>
              <strong>NPSN:</strong> {school.NPSN}
            </p>
            <p>
              <strong>Alamat:</strong> {school.alamat}
            </p>
            <p>
              <strong>ttDsekolah:</strong> {school.ttDsekolah}
            </p>
            <p>
              <strong>Kode Pos:</strong> {school.kodePos}
            </p>
            <p>
              <strong>Kecamatan:</strong> {school.Kec}
            </p>
            <p>
              <strong>Kabupaten:</strong> {school.kab}
            </p>
            <p>
              <strong>Provinsi:</strong> {school.prov}
            </p>
            <p>
              <strong>Status Sekolah:</strong> {school.statusSekolah}
            </p>
            <p>
              <strong>Jenjang Pendidikan:</strong> {school.JenjangPendidikan}
            </p>
            <p>
              <strong>Naungan:</strong> {school.naungan}
            </p>
            <p>
              <strong>No SK Pendirian:</strong> {school.noSKpendiri}
            </p>
            <p>
              <strong>Tanggal SK Pendirian:</strong> {school.tglSkPendiri}
            </p>
            <p>
              <strong>No SK Operasional:</strong> {school.noSkOprasional}
            </p>
            <p>
              <strong>Akreditasi:</strong> {school.Akreditasi}
            </p>
            <p>
              <strong>No SK Akreditasi:</strong> {school.noSkAkreditasi}
            </p>
            <p>
              <strong>Tanggal SK Akreditasi:</strong> {school.tglSkAkreditasi}
            </p>
            <p>
              <strong>No Sertifikat IOS:</strong> {school.noSertifikatIOS}
            </p>
            <p>
              <strong>Fax:</strong> {school.fax}
            </p>
            <p>
              <strong>Email:</strong> {school.email}
            </p>
            <p>
              <strong>Website:</strong> {school.website}
            </p>
            <div>
              <strong>Visi:</strong>
              <ul>
                {school.visi.map((visiItem, index) => (
                  <li key={index}>{visiItem.isiVisi}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>Misi:</strong>
              <ul>
                {school.misi.map((misiItem, index) => (
                  <li key={index}>{misiItem.isiMisi}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>Salam Kepsek:</strong>
              <ul>
                {school.salam_kepsek.map((salamKepsek, index) => (
                  <li key={index}>{salamKepsek.text}</li>
                ))}
              </ul>
            </div>
            <div>
              <strong>Sejarah Singkat:</strong>
              
              <ul>
                {school.sejarah_singkat.map((sejarah, index) => (
                  <li key={index}>{sejarah.text}</li>
                ))}
              </ul>
            </div>
            <button
              onClick={() => handleEditClick(school)}
              className="mt-4 bg-blue-500 text-white py-1 px-4 rounded"
            >
              Edit
            </button>
          </div>
        ))}
      </div>

      {editingSchool && (
        <form onSubmit={handleSubmit} className="mt-8">
          <h2 className="text-xl font-bold mb-4">Edit Data Sekolah</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* Form fields here */}
            <div>
              <label className="block font-medium">Nama Sekolah</label>
              <input
                type="text"
                name="namasekolah"
                value={formData.namasekolah}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">NPSN</label>
              <input
                type="text"
                name="NPSN"
                value={formData.NPSN}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Alamat</label>
              <input
                type="text"
                name="alamat"
                value={formData.alamat}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">ttDsekolah</label>
              <input
                type="text"
                name="ttDsekolah"
                value={formData.ttDsekolah}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Kode Pos</label>
              <input
                type="text"
                name="kodePos"
                value={formData.kodePos}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Kecamatan</label>
              <input
                type="text"
                name="Kec"
                value={formData.Kec}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Kabupaten</label>
              <input
                type="text"
                name="kab"
                value={formData.kab}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Provinsi</label>
              <input
                type="text"
                name="prov"
                value={formData.prov}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Status Sekolah</label>
              <input
                type="text"
                name="statusSekolah"
                value={formData.statusSekolah}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Jenjang Pendidikan</label>
              <input
                type="text"
                name="JenjangPendidikan"
                value={formData.JenjangPendidikan}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Naungan</label>
              <input
                type="text"
                name="naungan"
                value={formData.naungan}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">No SK Pendirian</label>
              <input
                type="text"
                name="noSKpendiri"
                value={formData.noSKpendiri}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Tanggal SK Pendirian</label>
              <input
                type="date"
                name="tglSkPendiri"
                value={formData.tglSkPendiri}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">No SK Operasional</label>
              <input
                type="text"
                name="noSkOprasional"
                value={formData.noSkOprasional}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Akreditasi</label>
              <input
                type="text"
                name="Akreditasi"
                value={formData.Akreditasi}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">No SK Akreditasi</label>
              <input
                type="text"
                name="noSkAkreditasi"
                value={formData.noSkAkreditasi}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Tanggal SK Akreditasi</label>
              <input
                type="date"
                name="tglSkAkreditasi"
                value={formData.tglSkAkreditasi}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">No Sertifikat IOS</label>
              <input
                type="text"
                name="noSertifikatIOS"
                value={formData.noSertifikatIOS}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Fax</label>
              <input
                type="text"
                name="fax"
                value={formData.fax}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div>
              <label className="block font-medium">Website</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
          <div className="mt-4">
            <label className="block font-medium">Visi</label>
            {formData.visi.map((visiItem, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={visiItem.isiVisi}
                  onChange={(e) => handleVisiMisiChange(e, index, "visi")}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveVisiMisi(index, "visi")}
                  className="ml-2 bg-red-500 text-white py-1 px-2 rounded"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddVisi}
              className="mt-2 bg-green-500 text-white py-1 px-4 rounded"
            >
              Add Visi
            </button>
          </div>
          <div className="mt-4">
            <label className="block font-medium">Misi</label>
            {formData.misi.map((misiItem, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={misiItem.isiMisi}
                  onChange={(e) => handleVisiMisiChange(e, index, "misi")}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveVisiMisi(index, "misi")}
                  className="ml-2 bg-red-500 text-white py-1 px-2 rounded"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddMisi}
              className="mt-2 bg-green-500 text-white py-1 px-4 rounded"
            >
              Add Misi
            </button>
          </div>
          <div className="mt-4">
            <label className="block font-medium">Salam Kepsek</label>
            <small>Pada bagian ini setiap teks akan jadi 1 paragraf</small>

            {formData.salam_kepsek.map((salam, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={salam.text || ""}
                  onChange={(e) => handleSalamChange(e, index)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveSalam(index)}
                  className="ml-2 bg-red-500 text-white py-1 px-2 rounded"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddSalam}
              className="mt-2 bg-green-500 text-white py-1 px-4 rounded"
            >
              Add Teks
            </button>
          </div>
          <div className="mt-4">
            <label className="block font-medium">Sejarah Singkat</label>
            <small>Pada bagian ini setiap teks akan jadi 1 paragraf</small>
            {formData.sejarah_singkat.map((sejarah, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  value={sejarah.text || ""}
                  onChange={(e) => handleSejarahChange(e, index)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveSejarah(index)}
                  className="ml-2 bg-red-500 text-white py-1 px-2 rounded"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddSejarah}
              className="mt-2 bg-green-500 text-white py-1 px-4 rounded"
            >
              Add Sejarah
            </button>
          </div>
          <div className="mt-8">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleCancelEdit}
              className="ml-4 bg-red-500 text-white py-2 px-4 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      <div className="flex justify-center my-5">
        <Link to={"/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default UpdateDataSekolah;
