import axios from "axios";
import React, { useState } from "react";

const TabelPersen = ({ mapel, onSubmit }) => {
  const [inputValues, setInputValues] = useState({
    Latihan: "",
    PR: "",
    Tugas: "",
    UH: "",
    MID: "",
    US: "",
    Praktikum: "",
  });
  const [error, setError] = useState(null);

  const handleChange = (jenis, e) => {
    setInputValues({
      ...inputValues,
      [jenis]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}api/persen/update/${mapel}`,
        inputValues,
        config
      );

      alert("Data berhasil diperbarui!");
      onSubmit(`/guru/elearning-guru/${mapel}`); // Call onSubmit to hide the form and reload the page
    } catch (error) {
      setError("Error updating persen: " + error.message);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Tabel Persen</h2>
      {error && <p className="text-red-500">{error}</p>}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {["Latihan", "PR", "Tugas", "UH", "MID", "US", "Praktikum"].map(
          (jenis, index) => (
            <div key={index} className="flex flex-col mb-2">
              <label className="mb-2 font-bold text-lg text-gray-700">
                {jenis}
              </label>
              <input
                type="number"
                value={inputValues[jenis] || 0}
                onChange={(e) => handleChange(jenis, e)}
                className="border border-gray-300 p-2 rounded-md"
              />
            </div>
          )
        )}
        <div className="flex items-center mb-2">
          <label className="mb-2 font-bold text-lg text-gray-700">Total</label>
          <input
            type="number"
            value={Object.values(inputValues).reduce(
              (total, value) => total + Number(value),
              0
            )}
            readOnly
            className="border border-gray-300 p-2 rounded-md"
          />
        </div>
      </div>
      <button
        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default TabelPersen;
