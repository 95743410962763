import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Back from "../button/back";

const categoryMapping = {
  BBKTYMEBM:
    "Beriman, Bertakwa Kepada Tuhan Yang Maha Esa, dan Berakhlak Mulia",
  BerkebhinekaanGlobal: "Berkebhinekaan Global",
  BergotongRoyong: "Bergotong Royong",
  Mandiri: "Mandiri",
  BernalarKritis: "Bernalar Kritis",
  Kreatif: "Kreatif",
};

const subCategoryMapping = {
  akhlakberagama: "Akhlak beragama",
  AkhlakPribadi: "Akhlak Pribadi",
  akhlakkepadamanusia: "Akhlak kepada manusia",
  akhlakkepadaalam: "Akhlak kepada alam",
  akhlakbernegara: "Akhlak bernegara",
  mengenaldanmenghargaibudaya: "Mengenal dan menghargai budaya",
  komunikasidaninteraksiantarbudaya: "Komunikasi dan interaksi antarbudaya",
  refleksidanbertanggungjawabterhadappengalamankebinekaan:
    "Refleksi dan bertanggungjawab terhadap pengalaman kebinekaan",
  BerkeadilanSosial: "Berkeadilan Sosial",
  kolaborasi: "Kolaborasi",
  kepedulian: "Kepedulian",
  Berbagi: "Berbagi",
  Pemahamandiridansituasiyangdihadapi:
    "Pemahamandiri dan situasi yang dihadapi",
  RegulasiDiri: "RegulasiDiri",
  memperolehdanmemprosesinformasidangagasan:
    "Memperoleh dan memproses informasi dan gagasan",
  menganalisisdanmengevaluasipenalarandanprosedurnya:
    "Menganalisis dan mengevaluasi penalaran dan prosedurnya",
  Elemenrefleksipemikirandanprosesberpikir:
    "Refleksi pemikiran dan proses berpikir",
  menghasilkangagasanyangorisina: "Menghasilkan gagasan yang orisina",
  memilikikeluwesanberpikirdalammencarialternatifsolusipermasalahan:
    "Memiliki keluwesan berpikir dalam mencari alternatif solusi permasalahan",
};

const ListP5 = () => {
  const [data, setData] = useState([]);
  const [filterClass, setFilterClass] = useState("");
  const [tahunInput, setTahunInput] = useState("");
  const [semesterInput, setSemesterInput] = useState("");
  const [namaproyek, setNamaProyek] = useState("");
  const [deskripsiproyek, setDeskripsiProyek] = useState("");
  const [selectedElements, setSelectedElements] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/p5/all`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleFilterChange = (event) => {
    setFilterClass(event.target.value);
  };

  const handleElementSelect = (category, point) => {
    setSelectedElements((prev) => {
      const existingElementIndex = prev.findIndex(
        (el) => el.element === category
      );

      if (existingElementIndex !== -1) {
        const existingElement = prev[existingElementIndex];

        // Cek apakah poin sudah ada di dalam deskripsielmen
        if (existingElement.deskripsielmen.includes(point)) {
          // Hapus point dari deskripsielmen
          const updatedDescriptions = existingElement.deskripsielmen.filter(
            (desc) => desc !== point
          );

          if (updatedDescriptions.length === 0) {
            // Jika tidak ada deskripsi yang tersisa, hapus elemen sepenuhnya
            return prev.filter((el, idx) => idx !== existingElementIndex);
          } else {
            // Jika masih ada deskripsi, perbarui elemen
            return prev.map((el, idx) =>
              idx === existingElementIndex
                ? { ...el, deskripsielmen: updatedDescriptions }
                : el
            );
          }
        } else {
          // Tambahkan point baru ke deskripsielmen
          return prev.map((el, idx) =>
            idx === existingElementIndex
              ? { ...el, deskripsielmen: [...el.deskripsielmen, point] }
              : el
          );
        }
      } else {
        // Tambahkan elemen baru jika belum ada
        return [...prev, { element: category, deskripsielmen: [point] }];
      }
    });
  };

  const handleSave = async () => {
    const payload = {
      kelas: filterClass,
      fase: data[0]?.fase,
      tahun: tahunInput,
      semester: semesterInput,
      namaproyek: namaproyek,
      deskripsiproyek: deskripsiproyek,
      data: selectedElements,
    };

    try {
      const token = localStorage.getItem("token");
      const url = `${process.env.REACT_APP_API_URL}api/p5pilih/all`; //

      await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert("Data berhasil disimpan");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Data tidak berhasil disimpan");
    }
  };

  const filteredData = data.filter((item) => {
    return filterClass === "" || item.kelas === filterClass;
  });

  const allFiltersFilled = () => {
    return (
      filterClass !== "" &&
      tahunInput !== "" &&
      semesterInput !== "" &&
      namaproyek !== "" &&
      deskripsiproyek !== ""
    );
  };

  console.log("filter dara", filteredData);

  return (
    <div className="mx-5">
      <div className="my-3">
        <Link to={"/admin" || "/guru"}>
          <Back />
        </Link>
      </div>

      <div className="bg-slate-400/40 p-5 mx-5 rounded-lg">
        <div className="flex justify-center font-bold text-2xl mb-5">
          <h1>List Data P5</h1>
        </div>
        <div className="grid gap-y-2">
          <label>Input Kelas</label>
          <select value={filterClass} onChange={handleFilterChange}>
            <option value="">All</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
          <label>Input tahun</label>
          <input
            type="text"
            name="tahun"
            value={tahunInput}
            onChange={(e) => setTahunInput(e.target.value)}
          />
          <label>Input Semeter</label>
          <select
            value={semesterInput}
            onChange={(e) => setSemesterInput(e.target.value)}
          >
            <option value="">semester</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
          <label>Nama Proyek</label>
          <input
            type="text"
            name="namaproyek"
            value={namaproyek}
            onChange={(e) => setNamaProyek(e.target.value)}
          />
          <label>Deskripsi Proyek</label>
          <textarea
            name="deskripsiproyek"
            value={deskripsiproyek}
            onChange={(e) => setDeskripsiProyek(e.target.value)}
          />
        </div>
      </div>

      <div>
        {allFiltersFilled() ? (
          filteredData.length === 0 ? (
            <div className="flex justify-center">
              <Link to={"/listp5/inputdatap5"}>
                <button className="bg-green-400 px-5 py-2 mb-10 rounded-lg text-white font-bold hover:bg-green-700">
                  Add New P5
                </button>
              </Link>
            </div>
          ) : (
            filteredData.map((item) => (
              <div key={item._id}>
                {item.data.map((categoryData, categoryIndex) => (
                  <div key={categoryIndex}>
                    {Object.keys(categoryData).map((category) => (
                      <div key={category}>
                        {category !== "_id" && (
                          <div>
                            <div
                              className={`grid justify-center bg-slate-400/20 m-5 rounded-lg shadow-black ${
                                selectedElements.some(
                                  (el) =>
                                    el.element ===
                                    (categoryMapping[category] || category)
                                )
                                  ? "bg-green-100"
                                  : ""
                              }`}
                            >
                              <div className="flex justify-center py-4 w-auto bg-blue-400 px-5">
                                <h4 className="2xl font-bold ">
                                  {categoryMapping[category] || category}
                                </h4>
                              </div>

                              {Array.isArray(categoryData[category]) &&
                                categoryData[category].map(
                                  (subCategoryData, subCategoryIndex) => (
                                    <div key={subCategoryIndex}>
                                      {Object.keys(subCategoryData).map(
                                        (subCategory) => (
                                          <div key={subCategory}>
                                            {subCategory !== "_id" && (
                                              <div>
                                                <div className="grid  bg-slate-200 ">
                                                  <div className="py-4 px-4 font-bold">
                                                    <h5 className="text-left">
                                                      {subCategoryMapping[
                                                        subCategory
                                                      ] || subCategory}
                                                    </h5>
                                                  </div>

                                                  {Array.isArray(
                                                    subCategoryData[subCategory]
                                                  ) && (
                                                    <div className="ml-10 px-5">
                                                      {subCategoryData[
                                                        subCategory
                                                      ].map(
                                                        (point, pointIndex) => (
                                                          <div
                                                            key={pointIndex}
                                                            className="pb-5"
                                                          >
                                                            <label>
                                                              <input
                                                                type="checkbox"
                                                                value={point}
                                                                onChange={() =>
                                                                  handleElementSelect(
                                                                    categoryMapping[
                                                                      category
                                                                    ] ||
                                                                      category,
                                                                    point
                                                                  )
                                                                }
                                                                checked={selectedElements.some(
                                                                  (el) =>
                                                                    el.element ===
                                                                      (categoryMapping[
                                                                        category
                                                                      ] ||
                                                                        category) &&
                                                                    el.deskripsielmen.includes(
                                                                      point
                                                                    )
                                                                )}
                                                              />
                                                              {point}
                                                            </label>
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
                <div>
                  <div className="mx-5">
                    <Link to={`/editp5/${filteredData[0]._id}`}>
                      <button className="bg-green-400 hover:bg-green-700 px-5 py-2 rounded-lg font-bold">
                        Edit
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          <div className="flex justify-center my-10">
            <p className="text-red-500">Please fill all the filter inputs.</p>
          </div>
        )}
      </div>
      <div className="flex justify-center">
        <button
          className="bg-blue-400 px-5 py-2 mb-10 rounded-lg text-white font-bold hover:bg-blue-700"
          onClick={handleSave}
        >
          Simpan{" "}
        </button>
      </div>
      <div className="flex justify-center mb-20">
        <Link to={"/guru" && "/admin"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default ListP5;
