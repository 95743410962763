import axios from "axios";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Back from "../../components/button/back";
import { storage } from "../FirebaseImage";

const AddBerita = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { ID } = location.state || {}; // Mengambil ID dari state jika ada
  const [formData, setFormData] = useState({
    judul: "",
    deskripsi: "",
    gambar: "",
    tanggal: "", // Tambahkan tanggal
  });
  const [file, setFile] = useState(null);

  // Handle perubahan input teks
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle perubahan file
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Mengambil data berita untuk edit jika ada ID
  useEffect(() => {
    if (ID) {
      const fetchBerita = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/berita/${ID}`
          );
          const berita = response.data;

          // Format tanggal agar hanya tanggalnya saja
          const formattedTanggal = new Date(berita.tanggal).toLocaleDateString(
            "en-CA"
          ); // Format yyyy-mm-dd

          setFormData({
            judul: berita.judul,
            deskripsi: berita.deskripsi,
            gambar: berita.gambar,
            tanggal: formattedTanggal, // Gunakan tanggal yang sudah diformat
          });
        } catch (error) {
          console.error("Error fetching berita:", error);
        }
      };
      fetchBerita();
    }
  }, [ID]);

  // Submit data
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file && !formData.gambar) {
      console.error("File belum dipilih");
      return;
    }

    const gambarURL = file
      ? await uploadImage(file) // Upload gambar jika file baru dipilih
      : formData.gambar; // Gunakan gambar lama jika tidak ada file baru

    const beritaData = {
      judul: formData.judul,
      deskripsi: formData.deskripsi,
      gambar: gambarURL,
      tanggal: formData.tanggal, // Tambahkan tanggal
    };

    try {
      let response;
      if (ID) {
        // Update berita jika ada ID
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}api/berita/${ID}`,
          beritaData
        );
      } else {
        // Tambah berita jika tidak ada ID
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/berita`,
          beritaData
        );
      }

      console.log("Berita saved:", response.data);

      // Reset form
      setFormData({ judul: "", deskripsi: "", gambar: "", tanggal: "" });
      setFile(null);

      // Navigasi setelah berhasil
      navigate("/admin/berita");
    } catch (error) {
      console.error("Error saving berita:", error);
    }
  };

  // Fungsi untuk upload gambar ke Firebase Storage
  const uploadImage = async (file) => {
    const fileName = `${uuidv4()}-${file.name}`;
    const storageRef = ref(storage, `berita/${fileName}`);
    const uploadResult = await uploadBytes(storageRef, file);
    return await getDownloadURL(uploadResult.ref);
  };

  return (
    <div className="bg-blue-200 p-3">
      <div className="container mx-auto mt-5 px-5 py-2 lg:px-20 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-5">
          {ID ? "Edit Berita" : "Tambah Berita"}
        </h2>
        <form onSubmit={handleSubmit}>
          {/* Input Judul */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="judul"
            >
              Judul
            </label>
            <input
              type="text"
              name="judul"
              value={formData.judul}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Input Tanggal */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="tanggal"
            >
              Tanggal
            </label>
            <input
              type="date"
              name="tanggal"
              value={formData.tanggal}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          {/* Input File Gambar */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="gambar"
            >
              Gambar
            </label>
            <input
              type="file"
              name="gambar"
              onChange={handleFileChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {formData.gambar && !file && (
              <div className="mt-2">
                <img
                  src={formData.gambar}
                  alt="Current Gambar"
                  className="w-32 h-32 object-cover"
                />
              </div>
            )}
          </div>

          {/* Input Deskripsi */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="deskripsi"
            >
              Deskripsi
            </label>
            <textarea
              name="deskripsi"
              value={formData.deskripsi}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="5"
              required
            ></textarea>
          </div>

          {/* Tombol Submit */}
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Submit
          </button>
        </form>
      </div>
      <div className="flex justify-center my-5">
        <Back onClick={() => navigate("/kelolaberita")} />
      </div>
    </div>
  );
};

export default AddBerita;
