import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom";
import KertasA4 from "./KertasA4";

const ViewRapor = () => {
  const { id, di, sid, tahun, semester, kelas, rombel } = useParams();
  const [raporDetails, setRaporDetails] = useState(null);
  const [raporDetailItem, setRaporDetailItem] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [nilais, setNilais] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datap5, setDataP5] = useState([]);

  useEffect(() => {
    const fetchRaporDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const raporUrl = `${process.env.REACT_APP_API_URL}api/dataRapor/getbyid/${id}`;

        const [raporResponse] = await Promise.all([
          axios.get(raporUrl, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const raporData = { ...raporResponse.data };

        const dataSiswa = raporData.dataKeterangan;
        const dataNilai = raporData.dataNilai.filter(
          (items) => (items.studentId = sid)
        );

        setNilais(dataNilai); // Ensure data is correctly set as array
        setRaporDetails(raporData);
        setUserDetails(dataSiswa[0]);

        const filteredData = raporData.dataKeterangan.filter(
          (item) => item._id === di
        );
        setRaporDetailItem(filteredData.length > 0 ? filteredData[0] : null);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching rapor details:", error);
        setLoading(false);
      }
    };

    fetchRaporDetails();
  }, [id, di, sid, tahun, semester, kelas, rombel]);

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}api/auth/users/${createdBy}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setGuru(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user profile:", error);
  //     });
  // }, [createdBy]);

  useEffect(() => {
    const fetchRaporDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/p5rapor/all`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filter = response.data.filter((user) => {
          return (
            user.tahun === tahun &&
            user.semester === semester &&
            user.kelas === kelas
          );
        });
        console.log("datap5", filter);
        setDataP5(filter);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchRaporDetails();
  }, [kelas, semester, tahun]);

  const getSemesterText = (semester) => {
    switch (semester) {
      case "1":
        return "Satu";
      case "2":
        return "Dua";
      default:
        return "";
    }
  };

  const getkelasText = (kelas) => {
    switch (kelas) {
      case "1":
        return "A";
      case "2":
        return "A";
      case "3":
        return "B";
      case "4":
        return "B";
      case "5":
        return "C";
      case "6":
        return "C";
      default:
        return "";
    }
  };

  const subjectMapping = {
    PAI: "Pendidikan Agama dan Budi Pekerti",
    PKN: "Pendidikan Pancasila",
    BIndo: "Bahasa Indonesia",
    MTK: "Matematika",
    IPA: "Ilmu Pengetahuan Alam",
    IPS: "Ilmu Pengetahuan Sosial",
    IPAS: "Ilmu Pengetahuan Alam dan Sosial",
    PJOK: "Pendidikan Jasmani Olahraga dan Kesehatan",
    Senibudaya: "Seni dan Budaya",
    BIng: "Bahasa Inggris",
    MULOK: "Muatan Lokal",
  };

  const subjectOrder = [
    "PAI",
    "PKN",
    "BIndo",
    "MTK",
    "IPA",
    "IPS",
    "IPAS",
    "PJOK",
    "Senibudaya",
    "BIng",
    "MULOK",
  ];

  const getMappedSubjectName = (subjectCode) => {
    return subjectMapping[subjectCode] || subjectCode;
  };

  const sortNilaisBySubjectOrder = (nilais) => {
    return nilais.sort((a, b) => {
      return subjectOrder.indexOf(a.mapel) - subjectOrder.indexOf(b.mapel);
    });
  };

  const handlePrint = async () => {
    const ids = ["rapor", "rapor1", "rapor2"]; // ID dari elemen-elemen yang akan dicetak

    const pdf = new jsPDF("p", "mm", "a4");

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const input = document.getElementById(id);

      if (!input) {
        console.error(`Element with id '${id}' not found`);
        continue;
      }

      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL("image/png");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = imgWidth / pdfWidth;
      const adjustedHeight = imgHeight / ratio;

      let yPosition = 0;

      while (yPosition < adjustedHeight) {
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -yPosition,
          pdfWidth,
          adjustedHeight > pdfHeight ? pdfHeight : adjustedHeight
        );

        yPosition += pdfHeight;

        if (yPosition < adjustedHeight) {
          pdf.addPage();
        }
      }

      // Tambahkan halaman baru setelah setiap elemen kecuali yang terakhir
      if (i < ids.length - 1) {
        pdf.addPage();
      }
    }

    pdf.save(`rapor-${userDetails.name}.pdf`);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!raporDetailItem || !userDetails) {
    return <p>No data available</p>;
  }

  return (
    <div className="relative min-h-screen bg-gray-100 flex items-center justify-center overflow-x-auto">
      <div className="flex-shrink-0 ">
        <div className="flex justify-center items-center bg-red mt-10 ">
          <button
            onClick={handlePrint}
            className="  px-4 py-2 bg-blue-500 text-white rounded "
          >
            Download PDF
          </button>
        </div>
        <div id="rapor">
          <KertasA4>
            <header className="mt-5">
              <div className="flex justify-center mb-3">
                <h1 className="text-[16px]  font-arial">
                  Laporan Hasil Belajar (Rapor)
                </h1>
              </div>

              <div className="flex justify-between text-[12px] border-b border-black pb-2  ">
                <div className="flex justify-between gap-10 mr-32  w-[400px]">
                  <div>
                    <h2>Nama</h2>
                    <h2>NISN</h2>
                    <h2>Sekolah</h2>
                    <h2>Alamat</h2>
                  </div>
                  <div className="">
                    <h2>: {userDetails.name}</h2>
                    <h2>: {userDetails.nisn}</h2>
                    <h2>: {raporDetails.nama_sekolah}</h2>
                    <h2>: {raporDetails.alamat_sekolah}</h2>
                  </div>
                </div>
                <div className="flex justify-between gap-10  w-[300px]">
                  <div>
                    <h2>Kelas</h2>
                    <h2>Fase</h2>
                    <h2>Semester</h2>
                    <h2>Tahun Pembelajaran</h2>
                  </div>
                  <div>
                    <h2>
                      : KELAS {raporDetails.kelas}
                      {raporDetails.rombel}
                    </h2>
                    <h2>: {getkelasText(raporDetails.kelas)} </h2>
                    <h2>
                      : {raporDetails.semester} (
                      {getSemesterText(raporDetails.semester)})
                    </h2>
                    <h2>
                      : {raporDetails.tahun} /{" "}
                      {parseInt(raporDetails.tahun) + 1}
                    </h2>
                  </div>
                </div>

                <br />
              </div>
            </header>
            <br />
            <main className="font-arial flex-grow">
              <div className="mb-5">
                <h1 className="text-[12px] font-semibold">
                  A. NILAI DAN CAPAIAN KOMPETENSI
                </h1>
              </div>
              <div className=" h-[775px] w-auto font-arial ">
                <table className="border border-black">
                  <thead>
                    <tr>
                      <th className="pb-5 font-semibold text-[12px] border-r border-black  w-9  ">
                        No
                      </th>
                      <th className="pb-5 font-semibold text-[12px] border-r border-black w-52 px-4">
                        Mata Pelajaran
                      </th>
                      <th className="pb-5 font-semibold text-[12px] border-r border-black w-10 px-4 ">
                        Nilai Akhir
                      </th>
                      <th className="pb-5 font-semibold text-[12px] border-0 border-black w-[700px] px-4 ">
                        Capaian Kompetensi
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[12px]">
                    {sortNilaisBySubjectOrder(nilais).map((user, index) => (
                      <React.Fragment key={user._id}>
                        <tr>
                          <td className="h-auto pb-2 pt-0 text-center border-r border-t border-black ">
                            {index + 1}
                          </td>
                          <td className="h-auto pb-2 pt-0 border-r border-t border-black px-4">
                            {user.nilai.map((data) => (
                              <div key={data.id} className="mb-2">
                                {" "}
                                {/* Membungkus setiap item */}
                                <span>
                                  {getMappedSubjectName(data.mapel)}
                                </span>{" "}
                                {/* Nama Mapel */}
                              </div>
                            ))}
                          </td>
                          <td className="h-auto pb-2 pt-0 border-r border-t border-black px-4 text-center">
                            {user.nilai.map((data) => (
                              <div key={data._id}>{data.finalScore}</div>
                            ))}
                          </td>
                          <td className="h-auto pb-0 pt-0 mt-0 mb-0 border-t border-black p-0">
                            {user.nilai.map((data) => (
                              <React.Fragment key={data._id}>
                                {data.CPtercapi.length > 0 && (
                                  <div className="pb-2 pt-0 mt-0 mb-0 border-b-1 border-black ">
                                    <h1 className="pl-1">
                                      Kompetensi{" "}
                                      {data.CPtercapi.map((cp, index) => (
                                        <span key={index} className="">
                                          {cp}
                                        </span>
                                      ))}{" "}
                                      telah tercapai
                                    </h1>
                                  </div>
                                )}
                                {data.CPditingkatkan.length > 0 && (
                                  <div
                                    className={` ${
                                      data.CPtercapi.length > 0
                                        ? "pb-2 border-t border-black"
                                        : ""
                                    }`}
                                  >
                                    <h1 className="pl-1">
                                      Perlu peningkatan dalam{" "}
                                      {data.CPditingkatkan.map((cp, index) => (
                                        <span key={index}> {cp}</span>
                                      ))}
                                    </h1>
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </main>
            <footer className="text-center mt-2 border-t border-black pt-1 font-arial text-[9px] font-semibold ">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <h1>
                    KELAS {userDetails.kelas}
                    {userDetails.rombel}
                  </h1>
                  <h1> | </h1>
                  <h1>{userDetails.name}</h1>
                  <h1> | </h1>
                  <h1>{userDetails.nipd}</h1>
                </div>
                <div className="flex gap-5">
                  <h1>Halaman</h1>
                  <h1>: 1</h1>
                </div>
              </div>
            </footer>
          </KertasA4>
        </div>
        <div id="rapor1">
          <KertasA4>
            <header className="relative mt-5">
              <div className="flex justify-between text-[12px] border-b border-black pb-2  ">
                <div className="flex justify-between gap-10 mr-32 w-[400px]">
                  <div>
                    <h2>Nama</h2>
                    <h2>NISN</h2>
                    <h2>Sekolah</h2>
                    <h2>Alamat</h2>
                  </div>
                  <div className="">
                    <h2>: {userDetails.name}</h2>
                    <h2>: {userDetails.nisn}</h2>
                    <h2>: {raporDetails.nama_sekolah}</h2>
                    <h2>: {raporDetails.alamat_sekolah}</h2>
                  </div>
                </div>
                <div className="flex justify-between gap-10  w-[300px]">
                  <div>
                    <h2>Kelas</h2>
                    <h2>Fase</h2>
                    <h2>Semester</h2>
                    <h2>Tahun Pembelajaran</h2>
                  </div>
                  <div>
                    <h2>
                      : KELAS {raporDetails.kelas}
                      {raporDetails.rombel}
                    </h2>
                    <h2>: {getkelasText(raporDetails.kelas)} </h2>
                    <h2>
                      : {raporDetails.semester} (
                      {getSemesterText(raporDetails.semester)})
                    </h2>
                    <h2>
                      : {raporDetails.tahun} /{" "}
                      {parseInt(raporDetails.tahun) + 1}
                    </h2>
                  </div>
                </div>

                <br />
              </div>
            </header>
            <br />
            <main className="font-arial flex-grow  h-[868px]">
              <div className="mb-1">
                <h1 className="text-[12px] font-semibold">
                  B. EKSTRAKURIKULER
                </h1>
              </div>
              <div className=" h-auto w-auto font-arial ">
                <table className="border border-black">
                  <thead>
                    <tr>
                      <th className="font-semibold pb-3 text-[12px]  border-black  w-9  ">
                        No
                      </th>
                      <th className="font-semibold pb-3 text-[12px] border-l border-black w-60 px-4">
                        Kegiatan Ekstrakurikuler
                      </th>
                      <th className="font-semibold pb-3 text-[12px] border-l border-black w-[460px] px-4 ">
                        Keterangan
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-[12px]">
                    {raporDetailItem.ekskul &&
                    raporDetailItem.ekskul.length > 0 ? (
                      raporDetailItem.ekskul.map((ekskul, index) => (
                        <>
                          <tr key={index}>
                            <td className="text-center pb-3  border-t border-black ">
                              {index + 1}
                            </td>
                            <td className="border-l pb-3 border-t border-black px-4">
                              {ekskul.ekskulName}
                            </td>
                            <td className="border-l pb-3 border-t border-black px-4">
                              {ekskul.ekskulKet}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td className="text-center pb-3 border-t border-black">
                            1
                          </td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                        </tr>
                        <tr>
                          <td className="text-center pb-3 border-t border-black ">
                            2
                          </td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                          <td className="border-l border-t pb-3 border-black px-4"></td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="mt-3 mb-1">
                <h1 className="text-[12px]  font-semibold">
                  C. KETIDAKHADIRAN
                </h1>
              </div>
              <div className=" h-auto  font-arial text-[12px]">
                <table className="border border-black w-[370px]">
                  <thead>
                    <tr className="border-b  border-black ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2">
                          <h1>Sakit</h1>
                        </div>
                        <div className=" w-1/3  pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4  pb-1 flex justify-center">
                          <h1>{raporDetailItem.sakit}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b pb-3 border-black ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2 pb-1">
                          <h1>Izin</h1>
                        </div>
                        <div className=" w-1/3 pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4 pb-1 flex justify-center">
                          <h1>{raporDetailItem.izin}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                    <tr className=" pb-3  ">
                      <td className="pl-7 pb-1 flex justify-between">
                        <div className=" w-1/2 pb-1">
                          <h1>Tanpa Keterangan</h1>
                        </div>
                        <div className=" w-1/3  pb-1 flex justify-end">
                          <h1>:</h1>
                        </div>
                        <div className=" w-1/4 pb-1 flex justify-center">
                          <h1>{raporDetailItem.tanpaKeterangan}</h1>
                        </div>
                        <div className=" w-1/4 pb-1">
                          <h1>Hari</h1>
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="mt-3 mb-1">
                <h1 className="text-[12px] font-semibold">
                  D. CATATAN WALI KELAS
                </h1>
              </div>
              <div className=" h-auto w-auto font-arial text-[12px] ">
                <div className="border pb-3 border-black">
                  <h1 className="px-7  py-5 font-semibold">
                    {raporDetailItem.CatatanaWakel}
                  </h1>
                </div>
                {semester === "2" && (
                  <div className="border border-black mt-5 pl-10 pb-2">
                    <h1 className="font-semibold">Keputusan :</h1>
                    <h1>
                      Berdasarkan hasil yang dicapi pada semester 1 dan 2,
                      Pesertadidik ditetapkan :
                    </h1>
                    <h1 className="font-semibold">
                      ( Naik ke / Tinggal di kelas *)
                      <span className="font-normal pl-2">
                        : ........................ ( ......................... )
                      </span>
                    </h1>
                  </div>
                )}
              </div>
              <div className="flex justify-between mt-5 font-arial text-[12px] mx-2">
                <div className="">
                  <h1>Mengetahui</h1>
                  <h1>Orang Tua/Wali</h1>
                  <div className=" h-10"></div>
                  <h1>.........................</h1>
                </div>
                <div>
                  <div className="flex gap-1">
                    <h1>{raporDetails.kota},</h1>
                    <h1> {raporDetailItem.tanggal}</h1>
                    <h1>{raporDetailItem.bulan}</h1>
                    <h1>{tahun}</h1>
                  </div>
                  <h1>Wali Kelas</h1>
                  <div className=" h-5"></div>
                  <br />
                  <h1>{raporDetails.nama_wakel}</h1>
                  <h1>Nip.{raporDetails.nip_wakel}</h1>
                </div>
              </div>
              <div className="flex justify-center text-[12px]">
                <div>
                  <h1>Mengetahui</h1>
                  <h1>Kepala Sekolah </h1>
                  <div className=" h-5"></div>
                  <br />
                  <h1>{raporDetails.namakepsek}</h1>
                  <h1>Nip.{raporDetails.nip_kepsek}</h1>
                </div>
              </div>
            </main>
            <footer className="text-center mt-2 border-t border-black pt-1 font-arial text-[9px] font-semibold ">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <h1>
                    KELAS {userDetails.kelas}
                    {userDetails.rombel}
                  </h1>
                  <h1> | </h1>
                  <h1>{userDetails.name}</h1>
                  <h1> | </h1>
                  <h1>{userDetails.nipd}</h1>
                </div>
                <div className="flex gap-5">
                  <h1>Halaman</h1>
                  <h1>: 2</h1>
                </div>
              </div>
            </footer>
          </KertasA4>
        </div>
        <div id="rapor2">
          <KertasA4>
            <header className="relative ">
              <div className="flex justify-center mb-3">
                <h1 className="text-[16px]  font-arial">
                  RAPOR PROJEK PENGUATAN PROFIL PELAJAR PANCASILA
                </h1>
              </div>
              <div className="flex justify-between text-[12px] border-b border-black pb-2">
                <div className="flex justify-between gap-10 mr-32 w-[400px]">
                  <div>
                    <h2>Nama</h2>
                    <h2>NISN</h2>
                    <h2>Sekolah</h2>
                    <h2>Alamat</h2>
                  </div>
                  <div>
                    <h2>: {userDetails.name}</h2>
                    <h2>: {userDetails.nisn}</h2>
                    <h2>: {raporDetails.nama_sekolah}</h2>
                    <h2>: {raporDetails.alamat_sekolah}</h2>
                  </div>
                </div>
                <div className="flex justify-between gap-10 w-[300px]">
                  <div>
                    <h2>Kelas</h2>
                    <h2>Fase</h2>
                    <h2>Semester</h2>
                    <h2>Tahun Pembelajaran</h2>
                  </div>
                  <div>
                    <h2>
                      : KELAS {raporDetails.kelas}
                      {raporDetails.rombel}
                    </h2>
                    <h2>: {getkelasText(raporDetails.kelas)}</h2>
                    <h2>
                      : {raporDetails.semester} (
                      {getSemesterText(raporDetails.semester)})
                    </h2>
                    <h2>
                      : {raporDetails.tahun} /{" "}
                      {parseInt(raporDetails.tahun) + 1}
                    </h2>
                  </div>
                </div>
              </div>
            </header>
            <main className=" w-auto h-[890px] font-arial text-[12px] pt-5">
              <div>
                {datap5.map((user, index) => (
                  <div key={user._id}>
                    <div className="border pb-3 border-black px-1">
                      <div className="font-bold text-[13px] pb-2 flex gap-2 pt-1">
                        <h1>Projek Profil{index + 1}</h1>
                        <h1>|</h1>
                        <h1>{user.namaproyek}</h1>
                      </div>
                      <div>
                        <h1>{user.deskripsiproyek}</h1>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-5">
                {datap5.map((data, index) => (
                  <div key={data._id}>
                    <table className="border border-black w-full">
                      <thead>
                        <tr className="flex border-b border-black">
                          <th className="flex w-10 pt-3">{index + 1}</th>
                          <th className="w-[400px] pl-5 flex pt-3">
                            {data.namaproyek}
                          </th>
                          <th className="flex border-l border-black w-14">
                            Mulai Berkem bang
                          </th>
                          <th className="flex border-l border-black w-14">
                            Sudah Berkem bang
                          </th>
                          <th className="flex pb-3 border-l border-black w-14">
                            Berkem bang Sesuai Harapan
                          </th>
                          <th className="flex border-l  border-black w-14">
                            Sangat Berkem bang
                          </th>
                        </tr>
                        <tr className="border-b border-black h-5"></tr>
                      </thead>
                      <tbody>
                        {data.data
                          .filter((data) => data.studentId === sid)
                          .map((data, index) => (
                            <tr key={data._id} className="grid">
                              {data.item.map((item) => (
                                <div
                                  key={item._id}
                                  className="flex border-b border-black w-full"
                                >
                                  <div className="grid w-[440px]">
                                    <td className=" pl-10 h-10 pt-3 font-bold border-b  border-black bg-slate-400">
                                      {item.element}
                                    </td>
                                    <td className="relative  pl-14 h-14 flex items-center ">
                                      <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-2xl leading-none pl-5">
                                        •
                                      </span>
                                      {item.subelement}
                                    </td>
                                  </div>
                                  <div className="">
                                    <div className=" border-b bg-slate-400 border-black h-10 ">
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td className="border-l border-black w-14 h-10"></td>
                                      <td
                                        className="border-l border-black w-14
                                    h-10"
                                      ></td>
                                    </div>
                                    <div className="  h-14  flex">
                                      <td className="  flex items-center justify-center border-l h-14 w-14 border-black">
                                        {item.MB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.SB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.BSH && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                      <td className="w-14  flex items-center justify-center border-l h-14 border-black">
                                        {item.SGB && (
                                          <FaCheck className="text-black" />
                                        )}
                                      </td>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
              <div>
                {datap5.map((user) => (
                  <div key={user._id}>
                    {user.data
                      .filter((data) => data.studentId === sid)
                      .map((item) => (
                        <div key={item._id}>
                          <div className="border  border-black mt-2 h-24 p-1">
                            <div className="">
                              <h1 className="font-bold">Catatan Proses :</h1>
                            </div>
                            <div>
                              <h1>{item.catatanProses}</h1>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div className="flex justify-between mt-2 font-arial text-[12px] mx-2">
                <div className="">
                  <h1>Mengetahui</h1>
                  <h1>Orang Tua/Wali</h1>
                  <div className=" h-10"></div>
                  <h1>.........................</h1>
                </div>
                <div>
                  <div className="flex gap-1">
                    <h1>{raporDetails.kota},</h1>
                    <h1> {raporDetailItem.tanggal}</h1>
                    <h1>{raporDetailItem.bulan}</h1>
                    <h1>{tahun}</h1>
                  </div>

                  <h1>Wali Kelas</h1>
                  <div className=" h-5"></div>
                  <br />
                  <h1>{raporDetails.nama_wakel}</h1>
                  <h1>Nip.{raporDetails.nip_wakel}</h1>
                </div>
              </div>
              <div className="flex justify-center text-[12px]">
                <div>
                  <h1>Mengetahui</h1>
                  <h1>Kepala Sekolah </h1>
                  <div className=" h-5"></div>
                  <br />
                  <h1>{raporDetails.namakepsek}</h1>
                  <h1>Nip.{raporDetails.nip_kepsek}</h1>
                </div>
              </div>
            </main>
            <footer className="text-center border-t border-black pt-1 font-arial text-[9px] font-semibold">
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <h1>
                    KELAS {userDetails.kelas}
                    {userDetails.rombel}
                  </h1>
                  <h1> | </h1>
                  <h1>{userDetails.name}</h1>
                  <h1> | </h1>
                  <h1>{userDetails.nipd}</h1>
                </div>
                <div className="flex gap-5">
                  <h1>Halaman</h1>
                  <h1>: 3</h1>
                </div>
              </div>
            </footer>
          </KertasA4>
        </div>
      </div>
    </div>
  );
};

export default ViewRapor;
