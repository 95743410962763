import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Back from "../components/button/back";

const KURKUMlist = () => {
  const [TPData, setTPData] = useState([]);
  const [filterKelas, setFilterKelas] = useState("");
  const [filterSemester, setFilterSemester] = useState("");
  const [filterMapel, setFilterMapel] = useState("");

  useEffect(() => {
    fetchTPData();
  }, []);

  const fetchTPData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/tp`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTPData(response.data);
    } catch (error) {
      console.error("Error fetching TP data:", error);
    }
  };

  const filteredTPData = TPData.filter((item) => {
    if (filterKelas && item.kelas !== filterKelas) return false;
    if (filterSemester && item.semester !== filterSemester) return false;
    if (filterMapel && item.mapel !== filterMapel) return false;
    return true;
  });

  const isAllFiltersEmpty =
    filterKelas.trim() === "" &&
    filterSemester.trim() === "" &&
    filterMapel.trim() === "";

  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + " ...";
    }
    return text;
  };
  return (
    <div className="bg-white rounded-lg p-5 m-5 drop-shadow-lg mx-7 md:mx-5">
      <div className="flex justify-center">
        <h2 className="font-bold text-2xl">Daftar TP</h2>
      </div>

      <div>
        <Link to={`/addTP`}>
          <button className="bg-blue-400 px-3 py-2 rounded-lg font-bold text-white hover:bg-blue-600 mb-5">
            Tambah TP
          </button>
        </Link>
      </div>
     <div className="grid gap-4 bg-white p-5 rounded-lg border border-slate-300 shadow-md">
  {/* Pilihan Kelas */}
  <div className="flex items-center">
    <label className="w-1/3 font-medium">Kelas:</label>
    <select
      value={filterKelas}
      onChange={(e) => setFilterKelas(e.target.value)}
      className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
    >
      <option value="">Semua Kelas</option>
      {[1, 2, 3, 4, 5, 6].map((kelas) => (
        <option key={kelas} value={kelas}>
          {kelas}
        </option>
      ))}
    </select>
  </div>

  {/* Pilihan Semester */}
  <div className="flex items-center">
    <label className="w-1/3 font-medium">Semester:</label>
    <select
      value={filterSemester}
      onChange={(e) => setFilterSemester(e.target.value)}
      className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
    >
      <option value="">Semua Semester</option>
      {[1, 2].map((semester) => (
        <option key={semester} value={semester}>
          {semester}
        </option>
      ))}
    </select>
  </div>

  {/* Pilihan Mata Pelajaran */}
  <div className="flex items-center">
    <label className="w-1/3 font-medium">Mata Pelajaran:</label>
    <select
      value={filterMapel}
      onChange={(e) => setFilterMapel(e.target.value)}
      className="flex-1 border border-slate-500 rounded-lg px-4 py-2"
    >
      <option value="">Mata Pelajaran</option>
      {[
        "MTK",
        "BIndo",
        "IPA",
        "IPS",
        "BIng",
        "IPAS",
        "PKN",
        "PJOK",
        "MULOK",
        "PAI",
      ].map((mapel) => (
        <option key={mapel} value={mapel}>
          {mapel}
        </option>
      ))}
    </select>
  </div>
</div>

      {isAllFiltersEmpty && <p>Tidak ada data yang dipilih.</p>}
      {!isAllFiltersEmpty && filteredTPData.length === 0 && (
        <p>Tidak ada data yang sesuai dengan filter yang dipilih.</p>
      )}
      {!isAllFiltersEmpty && filteredTPData.length > 0 && (
        <table className="min-w-full bg-white mt-5">
          <thead>
            <tr>
              <th className="py-2">CP</th>
              <th className="py-2">Deskripsi</th>
              <th className="py-2">Aksi</th>
            </tr>
          </thead>
          <tbody>
            {filteredTPData.map((item) => (
              <tr key={item._id}>
                <td className="border-2 border-slate-500 px-4 py-2">
                  <ul>
                    {item.TP.map((tp, index) => (
                      <li key={index}>CP {index + 1}:</li>
                    ))}
                  </ul>
                </td>
                <td className="border-2 border-slate-900 px-4 py-2">
                  {item.TP.map((tp, index) => (
                    <p key={index}>{truncateText(tp.isiTP, 8)}</p>
                  ))}
                </td>
                <td className="border-2 border-slate-500 px-4 py-2 ">
                  <div className="flex justify-center">
                    <Link to={`/editTP/${item._id}`}>
                      <button className="bg-green-500 text-white px-4 py-2 rounded">
                        Edit
                      </button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Link to={"/admin"}>
          <Back />
        </Link>
    </div>
  );
};

export default KURKUMlist;
