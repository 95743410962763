import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Back from "../button/back";

const RaporP5 = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    kelas: "",
    fase: "",
    tahun: "",
    semester: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/p5pilih/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setData(response.data);
        setFilteredData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}api/p5pilih/all/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFilteredData((prevData) => prevData.filter((item) => item._id !== id));
      alert("Data berhasil dihapus");
    } catch (error) {
      console.error("Error deleting data:", error);
      alert("Data tidak berhasil dihapus");
    }
  };

  useEffect(() => {
    const filterData = () => {
      let tempData = data;
      if (filters.kelas) {
        tempData = tempData.filter((item) => item.kelas === filters.kelas);
      }
      if (filters.fase) {
        tempData = tempData.filter((item) => item.fase === filters.fase);
      }
      if (filters.tahun) {
        tempData = tempData.filter((item) => item.tahun === filters.tahun);
      }
      if (filters.semester) {
        tempData = tempData.filter(
          (item) => item.semester === filters.semester
        );
      }
      setFilteredData(tempData);
    };

    filterData();
  }, [filters, data]);

  const allFiltersFilled = () => {
    return (
      filters.kelas !== "" &&
      filters.fase !== "" &&
      filters.tahun !== "" &&
      filters.semester !== ""
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div>
        <div className="flex justify-center my-5">
          <h1 className="text-2xl font-bold">Element P5</h1>
        </div>

        <div className="mb-4 bg-slate-400/40 mx-5 p-5 rounded-lg grid ">
          <label className="mr-2">Kelas:</label>
          <select
            name="kelas"
            value={filters.kelas}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">Semua</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>

          <label className="ml-4 mr-2">Fase:</label>
          <select
            name="fase"
            value={filters.fase}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">Semua</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>

          <label className="ml-4 mr-2">Tahun:</label>
          <input
            type="text"
            name="tahun"
            value={filters.tahun}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
            placeholder="Contoh: 2023"
          />

          <label className="ml-4 mr-2">Semester:</label>
          <select
            name="semester"
            value={filters.semester}
            onChange={handleFilterChange}
            className="border border-gray-300 p-2 rounded-md"
          >
            <option value="">Semua</option>
            <option value="1">1</option>
            <option value="2">2</option>
          </select>
        </div>
        <div className="mx-5 mt-10 overflow-x-auto">
          {allFiltersFilled() ? (
            filteredData.length > 0 ? (
              <table className="min-w-full bg-white border border-black">
                <thead>
                  <tr>
                    <th className="py-2 border border-black">Nama Proyek</th>
                    <th className="py-2 border border-black">
                      Deskripsi Proyek
                    </th>
                    <th className="py-2 border border-black">Data</th>
                    <th className="py-2 border border-black">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item) => (
                    <tr key={item._id}>
                      <td className="py-2 px-2 border border-black">
                        {item.namaproyek}
                      </td>
                      <td className="py-2 px-2 border border-black">
                        {item.deskripsiproyek}
                      </td>
                      <td className="py-2 border border-black px-2">
                        <ul>
                          {item.data.map((dataItem, index) => (
                            <li key={index} className="font-bold">
                              {dataItem.element}
                              <ul>
                                {dataItem.deskripsielmen.map(
                                  (desc, descIndex) => (
                                    <li
                                      key={descIndex}
                                      className="font-normal pl-2"
                                    >
                                      <h1>
                                        {descIndex + 1}. <span>{desc}</span>
                                      </h1>
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <button
                          onClick={() => handleDelete(item._id)}
                          className="bg-red-400 hover:bg-red-700 text-white px-5 py-2 rounded-lg mx-2"
                        >
                          Hapus
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <div className="flex justify-center">
                  Tidak ada data yang sesuai filter
                </div>
                <div className="flex justify-center">
                  <Link to="/listp5/add">
                    <button className="bg-blue-400 hover:bg-blue-700 px-2 py-2 text-white rounded-lg font-bold mt-4">
                      Add Element P5
                    </button>
                  </Link>
                </div>
              </div>
            )
          ) : (
            <div>Isi semua filter untuk melihat tabel</div>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-5 mb-10">
        <Link to={"/admin" || "/guru"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default RaporP5;
