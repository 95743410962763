import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const GuruP5 = () => {
  const [data, setData] = useState([]);
  const [dataP5, setDataP5] = useState([]);
  const [filters, setFilters] = useState({
    kelas: "",
    semester: "",
    tahun: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataP5, setFilteredDataP5] = useState([]);
  const [input, setInput] = useState({ catatanProses: "" });
  const [checkboxState, setCheckboxState] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        const url = `${process.env.REACT_APP_API_URL}api/auth/users`;
        const p5Url = `${process.env.REACT_APP_API_URL}api/p5pilih/all`;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseP5 = await axios.get(p5Url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filter = response.data.filter((user) => user.role === "siswa");
        console.log("data", filter);
        console.log("dataP5", responseP5.data);
        setData(filter);
        setDataP5(responseP5.data);
        setFilteredData(filter);
        setFilteredDataP5(responseP5.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    const filterData = () => {
      let tempData = data;
      let tempDataP5 = dataP5;

      if (filters.kelas) {
        tempData = tempData.filter((item) => item.kelas === filters.kelas);
        tempDataP5 = tempDataP5.filter((item) => item.kelas === filters.kelas);
      }
      if (filters.semester) {
        tempDataP5 = tempDataP5.filter(
          (item) => item.semester === filters.semester
        );
      }
      if (filters.tahun) {
        tempDataP5 = tempDataP5.filter((item) => item.tahun === filters.tahun);
      }
      setFilteredData(tempData);
      setFilteredDataP5(tempDataP5);
    };

    filterData();
  }, [filters, data, dataP5]);

  const handleCheckboxChange = (
    e,
    userId,
    p5Id,
    type,
    dataIndex,
    descIndex
  ) => {
    const { checked } = e.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [`${userId}-${p5Id}-${type}-${dataIndex}-${descIndex}`]: checked,
    }));
  };
  const handleChange = (e, userId) => {
    const { value } = e.target;
    setInput((prevState) => ({
      ...prevState,
      [userId]: value,
    }));
  };

  const handleSave = async () => {
    const { kelas, semester, tahun } = filters;

    const formattedData = filteredData.map((user) => {
      return {
        nama: user.name,
        studentId: user._id,
        catatanProses: input[user._id] || "",
        item: filteredDataP5.flatMap((p5Item) => {
          return p5Item.data.flatMap((dataItem, dataIndex) => {
            return dataItem.deskripsielmen.map((desc, descIndex) => {
              return {
                element: dataItem.element,
                subelement: desc,
                MB:
                  checkboxState[
                    `${user._id}-${p5Item._id}-mulaiBerkembang-${dataIndex}-${descIndex}`
                  ] || false,
                SB:
                  checkboxState[
                    `${user._id}-${p5Item._id}-sudahBerkembang-${dataIndex}-${descIndex}`
                  ] || false,
                BSH:
                  checkboxState[
                    `${user._id}-${p5Item._id}-berkembangSesuaiHarapan-${dataIndex}-${descIndex}`
                  ] || false,
                SGB:
                  checkboxState[
                    `${user._id}-${p5Item._id}-sangatBerkembang-${dataIndex}-${descIndex}`
                  ] || false,
              };
            });
          });
        }),
      };
    });

    const dataToSave = {
      kelas,
      semester,
      tahun,
      namaproyek: filteredDataP5.length > 0 ? filteredDataP5[0].namaproyek : "",
      deskripsiproyek:
        filteredDataP5.length > 0 ? filteredDataP5[0].deskripsiproyek : "",
      data: formattedData,
    };

    console.log("Data yang akan disimpan:", dataToSave);

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/p5rapor/save`,
        dataToSave,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response from server:", response.data);
      toast.success("Data saved successfully!");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error(
          "Data for the specified class, semester, and year already exists."
        );
      } else {
        console.error("Error saving data:", error);
        toast.error("Failed to save data. Please try again later.");
      }
    }
  };

  return (
    <div className="p-4 font-arial">
      <ToastContainer />
      <div>
        <h1 className="text-xl font-bold mb-4">Add Rapor P5</h1>
        <div className="mb-4 flex flex-wrap">
          <div className="mr-4 mb-4">
            <label className="mr-2">Kelas:</label>
            <select
              name="kelas"
              value={filters.kelas}
              onChange={handleFilterChange}
              className="border border-gray-300 p-2 rounded-md"
            >
              <option value="">Semua</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>

          <div className="mr-4 mb-4">
            <label className="mr-2">Semester:</label>
            <select
              name="semester"
              value={filters.semester}
              onChange={handleFilterChange}
              className="border border-gray-300 p-2 rounded-md"
            >
              <option value="">Semua</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          </div>

          <div className="mr-4 mb-4">
            <label className="mr-2">Tahun:</label>
            <input
              type="text"
              name="tahun"
              value={filters.tahun}
              onChange={handleFilterChange}
              className="border border-gray-300 p-2 rounded-md"
              placeholder="Contoh: 2023"
            />
          </div>
        </div>
        <div>
          {filteredDataP5.map((item) => (
            <div key={item._id} className="mb-4">
              <div className="font-bold text-lg">
                <h1>{item.namaproyek}</h1>
              </div>
              <div className="text-gray-700 border border-slate-300 p-2">
                <h1>{item.deskripsiproyek}</h1>
              </div>
            </div>
          ))}
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full border border-gray-300 text-[12px]">
            <thead>
              <tr>
                <th className="border border-gray-300 w-5">No</th>
                <th className="border border-gray-300  w-52">Nama</th>
                <th className="border border-gray-300 p-2 w-48">
                  Catatan Proses
                </th>
                <th className="border border-gray-300 w-auto">Element P5</th>
                <th className="border border-gray-300 p-2 w-20">
                  Mulai berkembang
                </th>
                <th className="border border-gray-300 p-2 w-20">
                  Sudah Berkembang
                </th>
                <th className="border border-gray-300 p-2 w-20">
                  Berkembang Sesuai Harapan
                </th>
                <th className="border border-gray-300 p-2 w-20">
                  Sangat Berkembang
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((user, userIndex) => (
                <tr key={user._id}>
                  <td className="border border-gray-300 w-[6px]">
                    {userIndex + 1}
                  </td>
                  <td className="border border-gray-300 p-2 ">{user.name}</td>
                  <td>
                    <input
                      type="text"
                      value={input[user._id] || ""}
                      onChange={(e) => handleChange(e, user._id)}
                      placeholder="catatan proses"
                      className="border border-s-teal-300 mx-2 rounded-md"
                    />
                  </td>

                  <td className="border border-gray-300 ">
                    {filteredDataP5.map((p5Item) => (
                      <div key={p5Item._id}>
                        {p5Item.data.map((dataItem, dataIndex) => (
                          <div
                            key={dataItem._id}
                            className="border-b p-2 w-72 sm:w-50 md:w-72 lg:w-auto md:h-20"
                          >
                            <p className="font-bold ">
                              {dataIndex + 1}. {dataItem.element}
                            </p>
                            {dataItem.deskripsielmen.map((desc, descIndex) => (
                              <p key={descIndex}>
                                {descIndex + 1}. {desc}
                              </p>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </td>
                  {[
                    "mulaiBerkembang",
                    "sudahBerkembang",
                    "berkembangSesuaiHarapan",
                    "sangatBerkembang",
                  ].map((type) => (
                    <td key={type} className="border border-gray-300">
                      {filteredDataP5.map((p5Item) =>
                        p5Item.data.map((dataItem, dataIndex) =>
                          dataItem.deskripsielmen.map((desc, descIndex) => (
                            <div
                              key={`${user._id}-${p5Item._id}-${type}-${dataIndex}-${descIndex}`}
                              className="h-20  w-20 flex justify-center"
                            >
                              <input
                                type="checkbox"
                                name={`${user._id}-${p5Item._id}-${type}-${dataIndex}-${descIndex}`}
                                checked={
                                  checkboxState[
                                    `${user._id}-${p5Item._id}-${type}-${dataIndex}-${descIndex}`
                                  ] || false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    user._id,
                                    p5Item._id,
                                    type,
                                    dataIndex,
                                    descIndex
                                  )
                                }
                              />
                            </div>
                          ))
                        )
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          onClick={handleSave}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default GuruP5;
