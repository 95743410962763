import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Back from "../components/button/back";
const EkskulAll = () => {
  const [ekskuls, setEkskuls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEkskuls = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/ekskul`
        );
        setEkskuls(response.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchEkskuls();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mx-10 rounded-xl drop-shadow-lg  my-10 bg-white  ">
      <div className="flex justify-center py-10 ">
        <h1 className=" text-3xl font-bold mb-5">Ekskul</h1>
      </div>

      {ekskuls.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pb-10">
          {ekskuls.map((ekskul) => (
            <div
              key={ekskul._id}
              className="transform transition duration-300 hover:scale-110 rounded-lg shadow-lg w-72 mx-auto hover:shadow-xl bg-blue-400/30"
            >
              <div className="bg-gradient-to-br from-rose-100 via-purple-200 to-purple-200 m-2 h-3/6 rounded-lg">
                {ekskul.Gambar && (
                  <img
                    src={`${ekskul.Gambar}`}
                    alt={ekskul.Judul}
                    className="w-full h-32 object-cover mb-2"
                  />
                )}
              </div>
              <div className="px-5 pt-5 flex flex-col">
                <h2 className="font-semibold">{ekskul.Judul}</h2>

                <button
                  className="bg-blue-500 cursor-pointer text-white px-2 py-1 mt-2 rounded-md transition duration-150 hover:bg-blue-700"
                  type="button"
                  onClick={() => navigate(`/ekskul-satu/${ekskul._id}`)}
                >
                  Baca
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>No ekskul found</div>
      )}
      <div className="flex justify-center pb-10">
        <Link to={"/"}>
          <Back />
        </Link>
      </div>
    </div>
  );
};

export default EkskulAll;
